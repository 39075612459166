import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OverlayScrollbars from 'overlayscrollbars';

import { useDataContext } from 'contexts/data/data.context';
import { useUI } from 'contexts/ui.context';
import { useWindowSize } from 'utils/hooks/use-window-size';
import Loader from 'components/common/loader/loader';
import Heading from 'components/common/heading/heading';
import { DailyStatisticModalTable } from './daily-statistic-modal-elements';
import CloseButton from './close-button/close-button';

import {
  StyledModalTableWrapper,
  StyledHeadWrapper,
  StyledOverflow,
  StyledWrapper,
  StyledTable,
  StyledEmpty
} from '../../table-block.style';

const DailyStatisticModal = () => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const overflowWrapperRef = useRef(null);
  const { isMobile } = useWindowSize();
  const { dataLoading, closeModal } = useUI();
  const { statActiveDay, statistics } = useDataContext();

  useEffect(() => {
    let scrollbars;

    if (statistics.length > 0 && !dataLoading) {
      if (overflowWrapperRef.current && isMobile) {
        scrollbars = OverlayScrollbars(overflowWrapperRef.current, {
          scrollbars: {
            visibility: 'auto',
            autoHide: 'never'
          }
        });
      }
    }

    return () => {
      if (scrollbars) {
        scrollbars.destroy();
      }
    };
  }, [overflowWrapperRef, dataLoading, statistics, isMobile]);

  if (dataLoading) return <Loader />;

  return (
    <StyledModalTableWrapper px={[3, 3, 7]}>
      <StyledHeadWrapper>
        <Heading mb={4} variant="h3">
          {statActiveDay !== '' && statActiveDay}{' '}
          {t('dailyStatisticBlock.heading')}
        </Heading>
        <CloseButton handler={closeModal} />
      </StyledHeadWrapper>

      {statistics.length === 0 ? (
        <StyledEmpty>
          <p>{t('dailyStatisticBlock.empty')}</p>
        </StyledEmpty>
      ) : (
        <StyledWrapper ref={modalRef}>
          <StyledTable>
            <StyledOverflow ref={overflowWrapperRef}>
              <DailyStatisticModalTable rows={statistics} />
            </StyledOverflow>
          </StyledTable>
        </StyledWrapper>
      )}
    </StyledModalTableWrapper>
  );
};

export default DailyStatisticModal;
