import { memo } from 'react';
import { Link } from 'gatsby';

import { LogoBox, LogoImage } from './logo.style';

const Logo = ({ imageUrl, alt }) => {
  return (
    <LogoBox>
      <Link to="/">
        <LogoImage src={imageUrl} alt={alt} />
      </Link>
    </LogoBox>
  );
};

export default memo(Logo);
