import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import useCopyToClipboard from 'utils/hooks/use-copy-to-clipboard';

import CaretDownIcon from 'components/icons/CaretDownIcon';
import TooltipIcon from 'components/icons/TooltipIcon';
import Tooltip from 'components/common/tooltip/tooltip';
import CopyIcon from 'components/icons/CopyIcon';
import useNormalizeCell from './use-normalize-cell';

import {
  StyledActionButton,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow
} from './grid-table.style';

export const TableHead = ({ columns, requestSort, sortConfig, sortable }) => {
  const { t } = useTranslation();

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  return (
    <StyledTableHead>
      <StyledTableRow columns={columns.length}>
        {columns.map(({ title, preTitle = '', subtitle = '', dataIndex }) => (
          <StyledTableCell key={dataIndex} className="table-column-has-sorters">
            <div
              className={cx(
                'table-column-sorters',
                getClassNamesFor(dataIndex),
                {
                  'has-sortable': sortable
                }
              )}
              role="presentation"
              onClick={sortable ? () => requestSort(dataIndex) : undefined}
            >
              <span className="table-column-title">
                {preTitle !== '' ? `${preTitle} ${t(title)}` : t(title)}
              </span>
              {sortable && (
                <span className="table-column-sorter">
                  <span className="table-caret">
                    {dataIndex === 'workersStatus' ? (
                      <TooltipIcon />
                    ) : (
                      <CaretDownIcon />
                    )}
                  </span>
                </span>
              )}
            </div>
            {subtitle && (
              <span className="table-column-subtitle">{subtitle}</span>
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
};

const TableFootCell = ({ items, dataIndex }) => {
  const averageVal =
    items.reduce((prev, current) => prev + Number(current[dataIndex]), 0) /
    items.length;

  const { cellValue } = useNormalizeCell(averageVal, dataIndex, false);

  return (
    <StyledTableCell key={dataIndex} className="table-weight-bold">
      {cellValue}
    </StyledTableCell>
  );
};

export const TableFootRow = ({ items, columns }) => {
  const { t } = useTranslation();

  return (
    <StyledTableRow columns={columns.length}>
      <StyledTableCell className="table-weight-bold">
        {t('luckStatisticsControl.tableFootLabel')}:
      </StyledTableCell>
      {columns
        .filter(({ dataIndex }) => dataIndex !== 'dateTime')
        .map(({ dataIndex }) => (
          <TableFootCell items={items} dataIndex={dataIndex} />
        ))}
    </StyledTableRow>
  );
};

export const TableCell = ({ item, dataIndex }) => {
  const { t } = useTranslation();
  const { cellValue, className } = useNormalizeCell(item, dataIndex, true);
  const [copied, copy] = useCopyToClipboard(700);

  return (
    <StyledTableCell className={className}>
      {dataIndex === 'networkInfo.toAddress' ? (
        <>
          {cellValue}
          <StyledActionButton onClick={() => copy(cellValue)}>
            {copied ? (
              <Tooltip message={t('walletsBlock.tooltipText')}>
                <CopyIcon width={15} height={18} />
              </Tooltip>
            ) : (
              <CopyIcon width={15} height={18} />
            )}
          </StyledActionButton>
        </>
      ) : (
        cellValue
      )}
    </StyledTableCell>
  );
};

export const TableRow = ({ columns, item }) => {
  return (
    <StyledTableRow columns={columns.length}>
      {columns.map(({ dataIndex }) => (
        <TableCell key={dataIndex} item={item} dataIndex={dataIndex} />
      ))}
    </StyledTableRow>
  );
};
