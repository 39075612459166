import { useMemo, createContext, useContext, useReducer } from 'react';

import { dataReducer } from './data.reducer';

export const initialState = {
  activeCoin: null,
  workers: [],
  workersFiltered: [],
  workersActiveTab: 'active',
  statistics: [],
  statActiveDay: ''
};

const DataContext = createContext();

DataContext.displayName = 'DataContext';

export const DataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);

  const filterActive = () => dispatch({ type: 'WORKERS_FILTER_ACTIVE' });
  const filterInactive = () => dispatch({ type: 'WORKERS_FILTER_INACTIVE' });
  const setWorkers = (workers) => dispatch({ type: 'SET_WORKERS', workers });
  const setStatistics = (statistics) =>
    dispatch({ type: 'SET_STATISTICS', statistics });
  const setActiveDay = (day) => dispatch({ type: 'SET_ACTIVE_DAY', day });
  const setActiveCoin = (coin) => dispatch({ type: 'SET_ACTIVE_COIN', coin });

  const value = useMemo(
    () => ({
      ...state,
      filterActive,
      filterInactive,
      setWorkers,
      setStatistics,
      setActiveDay,
      setActiveCoin
    }),
    [state]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataContext = () => {
  const context = useContext(DataContext);

  if (context === undefined) {
    throw new ReferenceError('Use DataContext inside its provider.');
  }

  return context;
};
