import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { ModalEvents } from 'utils/constant';
import { useWallet } from 'contexts/wallet/wallet.context';
import { DELETE_AUTO_WITHDRAW } from 'graphql/withdraw.mutation';
import { ButtonGrid, Button } from 'components/common/button/button';
import { Wrapper, Heading, Text } from 'assets/styles/form.style';

import BaseModal from '../base-modal';

const WithdrawalConfirmModal = () => {
  const { t } = useTranslation();
  const { emitter, removeWithdrawal } = useWallet();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const [deleteAutoWithdraw, { loading }] = useMutation(DELETE_AUTO_WITHDRAW);

  useEffect(() => {
    const unbind = emitter.on(
      ModalEvents.CONFIRM_WITHDRAWAL_MODAL,
      (payload) => {
        setOpen(true);
        setData(payload);
      }
    );
    return () => unbind();
  }, [emitter]);

  const handleRemove = async () => {
    const result = await deleteAutoWithdraw({
      variables: {
        walletId: data.id
      },
      context: { clientName: 'private' }
    });

    if (result?.data?.deleteAutoWithdraw) {
      removeWithdrawal(data);
    }
    setOpen(false);
  };

  const submitAction = (
    <ButtonGrid>
      <Button
        loading={loading}
        onClick={data !== null ? handleRemove : undefined}
      >
        {t('withdrawalBlock.modal.confirm.buttonDeleteTxt')}
      </Button>
      <Button variant="gray" onClick={() => setOpen(false)}>
        {t('withdrawalBlock.modal.confirm.buttonCancelTxt')}
      </Button>
    </ButtonGrid>
  );

  return (
    <BaseModal state={[open, setOpen]} action={submitAction} hideActions>
      <Wrapper>
        <Heading>{t('withdrawalBlock.modal.confirm.title')}</Heading>
        <Text mb={4}>{t('withdrawalBlock.modal.confirm.text')}</Text>
      </Wrapper>
    </BaseModal>
  );
};

export default memo(WithdrawalConfirmModal);
