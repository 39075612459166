import {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect
} from 'react';

import { useQuery } from '@apollo/client';

import { GET_COINS } from 'graphql/coins.query';

const initialState = {
  activeCoin: null,
  coins: []
};

const CoinsContext = createContext();

CoinsContext.displayName = 'CoinsContext';

const CoinsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_COIN': {
      return {
        ...state,
        activeCoin: action.coin
      };
    }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const CoinsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CoinsReducer, initialState);
  const setActiveCoin = (coin) => dispatch({ type: 'SET_ACTIVE_COIN', coin });

  const { data, loading } = useQuery(GET_COINS, {
    variables: ''
  });

  useEffect(() => {
    if (!loading && data?.coins.length > 0) {
      setActiveCoin(data?.coins[0]);
    }
  }, [data?.coins, loading]);

  const value = useMemo(
    () => ({
      ...state,
      coins: loading || !data?.coins ? [] : data?.coins,
      loading,
      setActiveCoin
    }),
    [data?.coins, loading, state]
  );

  return (
    <CoinsContext.Provider value={value}>{children}</CoinsContext.Provider>
  );
};

export const useCoins = () => {
  const context = useContext(CoinsContext);

  if (context === undefined) {
    throw new ReferenceError('Use CoinsContext inside its provider.');
  }

  return context;
};
