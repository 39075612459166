import { createGlobalStyle } from 'styled-components';
import { get } from 'styled-system';
import css from '@styled-system/css';

export const GlobalStyle = createGlobalStyle(({ theme }) =>
  css({
    '*, *::before, *::after': {
      boxSizing: 'border-box'
    },
    body: {
      margin: 0,
      fontFamily: 'body',
      fontWeight: 'body',
      fontSize: 'base',
      lineHeight: 'body',
      overflowX: 'hidden',
      color: get(theme, 'colors.body.text'),
      backgroundColor: get(theme, 'colors.body.bg')
    },
    'h1, h2, h3, h4, h5, h6': {
      lineHeight: 'heading',
      fontWeight: 'heading',
      color: get(theme, 'colors.text.default')
    },
    h1: {
      fontFamily: 'heading',
      fontSize: '1xl',
      margin: 0
    },
    h2: {
      fontFamily: 'heading',
      fontSize: 'xl',
      margin: 0
    },
    h3: {
      fontFamily: 'heading',
      fontSize: 'lg',
      margin: 0
    },
    h4: {
      fontFamily: 'heading',
      fontSize: 'md',
      margin: 0
    },
    h5: {
      fontFamily: 'heading',
      fontSize: 'up',
      margin: 0
    },
    h6: {
      fontFamily: 'heading',
      fontSize: 'base',
      margin: 0
    },
    p: {
      fontSize: 'base',
      margin: 0,
      color: get(theme, 'colors.body.text')
    },
    button: {
      padding: '8px 20px',
      fontFamily: 'body',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
      backgroundColor: 'primary.light',
      borderRadius: 0,
      fontSize: 'xs',
      margin: 0
    },
    a: {
      fontFamily: 'body',
      textDecoration: 'none',
      color: 'linkColor',
      transition: get(theme, 'customs.transition')
    },
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyle: 'none'
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%',
      height: 'auto'
    },
    'body.modal-open': {
      paddingRight: '16px'
    },
    'input:active': {
      borderColor: 'input.border',
      outline: 'none'
    },
    'input:focus': {
      borderColor: 'input.border',
      outline: 'none'
    },
    'input[type=radio]': {
      padding: 0,
      margin: '0 12px 0 0',
      appearance: 'none',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: `1px solid ${get(theme, 'colors.white')}`,

      ':checked': {
        backgroundColor: get(theme, 'colors.white')
      }
    },
    '::-webkit-scrollbar': {
      width: '4px',
      height: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '2px',
      backgroundColor: get(theme, 'colors.input.border')
    },
    '::-webkit-input-placeholder': {
      color: get(theme, 'colors.input.placeholder')
    },
    '::-moz-placeholder': {
      color: get(theme, 'colors.input.placeholder')
    },
    ':-ms-input-placeholder': {
      color: get(theme, 'colors.input.placeholder')
    },
    ':-moz-placeholder': {
      color: get(theme, 'colors.input.placeholder')
    },
    'div.os-theme-dark>.os-scrollbar-horizontal': {
      borderRadius: '2px',
      height: '8px'
    },
    'div.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle':
      {
        backgroundColor: get(theme, 'colors.input.border')
      },
    'div.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle, .os-theme-dark>.os-scrollbar>.os-scrollbar-track':
      {
        borderRadius: '2px'
      }
  })
);
