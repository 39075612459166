import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Logo from 'layouts/logo/logo';
import LogoImage from 'assets/images/logo.svg';
import { useUI } from 'contexts/ui.context';
import { useUser } from 'contexts/user.context';
import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';

import LangSwitcher from 'layouts/lang-switcher/lang-switcher';
import ThemeSwitcher from 'layouts/theme-switcher/theme-switcher';

import Drawer from 'components/common/drawer/drawer';
import CloseIcon from 'components/icons/CloseIcon';
import UserIcon from 'components/icons/UserIcon';
import MainMenu from '../main-menu/main-menu';

import {
  StyledMobileWrapper,
  StyledHeader,
  StyledContent,
  StyledActions,
  StyledLinkButton,
  StyledAuthButton,
  StyledActionButton
} from './mobile-navigation.style';

const MobileNavigation = () => {
  const { t } = useTranslation();
  const { displaySidebar, closeSidebar } = useUI();
  const { clearData, isAuthenticated } = useUser();

  const onLogin = () => {
    navigate(AUTH_LOGIN_PAGE);
    closeSidebar();
  };

  const onLogout = () => {
    localStorage.removeItem('access_token');
    clearData();
    navigate('/');
  };

  return (
    <Drawer sidebar={displaySidebar}>
      <StyledMobileWrapper>
        <StyledHeader>
          <Logo imageUrl={LogoImage} />
          <StyledLinkButton onClick={closeSidebar}>
            <CloseIcon />
          </StyledLinkButton>
        </StyledHeader>
        <StyledContent>
          <MainMenu type="vertical" isAuthenticated={isAuthenticated} />
          {!isAuthenticated ? (
            <StyledAuthButton onClick={onLogin}>
              <UserIcon color="#6487FF" /> {t('header.login')}
            </StyledAuthButton>
          ) : (
            <StyledAuthButton onClick={onLogout}>
              <UserIcon color="#6487FF" /> {t('header.logout')}
            </StyledAuthButton>
          )}
        </StyledContent>
        <StyledActions>
          <StyledActionButton>
            <LangSwitcher type="list" />
          </StyledActionButton>
          <StyledActionButton>
            <ThemeSwitcher />
          </StyledActionButton>
        </StyledActions>
      </StyledMobileWrapper>
    </Drawer>
  );
};

export default MobileNavigation;
