import { createContext, useReducer, useMemo, useContext } from 'react';
import { createNanoEvents } from 'nanoevents';

import ModalRegistrar from 'components/wallets/wallet-modals/modal-registrar';
import { bigIntToFloat } from 'utils/helpers/converters';
import { walletReducer } from './wallet.reducer';

const emitter = createNanoEvents();

const initialState = {
  wallets: [],
  transactions: []
};

const WalletContext = createContext();

WalletContext.displayName = 'WalletContext';

export const WalletProvider = ({ children }) => {
  const [state, dispatch] = useReducer(walletReducer, initialState);

  const addTransactions = (transactions) => {
    dispatch({ type: 'ADD_TRANSACTIONS', transactions });
  };

  const addWithdrawal = (item) => {
    dispatch({ type: 'ADD_WITHDRAWAL', payload: { ...item } });
  };

  const editWithdrawal = (item) => {
    dispatch({ type: 'EDIT_WITHDRAWAL', payload: { ...item } });
  };

  const addWallet = (item) => {
    dispatch({
      type: 'ADD_WALLET',
      payload: {
        ...item,
        autoWithdraw:
          item.autoWithdraw !== null
            ? {
                ...item.autoWithdraw,
                fixedAmount: bigIntToFloat(item.autoWithdraw.fixedAmount)
              }
            : null
      }
    });
  };

  const addWallets = (wallets) => {
    const normalizeWallets = wallets.map((item) => {
      return {
        ...item,
        autoWithdraw:
          item.autoWithdraw !== null
            ? {
                ...item.autoWithdraw,
                fixedAmount: bigIntToFloat(item.autoWithdraw.fixedAmount)
              }
            : null
      };
    });
    dispatch({ type: 'ADD_WALLETS', payload: normalizeWallets });
  };

  const editWallet = (item) => {
    dispatch({
      type: 'EDIT_WALLET',
      payload: {
        ...item,
        autoWithdraw:
          item.autoWithdraw !== null
            ? {
                ...item.autoWithdraw,
                fixedAmount: bigIntToFloat(item.autoWithdraw.fixedAmount)
              }
            : null
      }
    });
  };

  const removeWithdrawal = (item) => {
    dispatch({ type: 'REMOVE_WITHDRAWAL', payload: { ...item } });
  };

  const removeWallet = (item) => {
    dispatch({ type: 'REMOVE_WALLET', payload: { ...item } });
  };

  const value = useMemo(
    () => ({
      ...state,
      emitter,
      addTransactions,
      addWithdrawal,
      editWithdrawal,
      removeWithdrawal,
      addWallet,
      addWallets,
      editWallet,
      removeWallet
    }),
    [state]
  );

  return (
    <WalletContext.Provider value={value}>
      {children}
      <ModalRegistrar />
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};
