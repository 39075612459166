import { AnimatePresence } from 'framer-motion';

import { StyledDrawer, SidekickBody } from './drawer.style';

const Drawer = ({ sidebar, children }) => {
  return (
    <AnimatePresence>
      {sidebar && (
        <StyledDrawer>
          <SidekickBody
            initial={{ x: '-100%' }}
            animate={{
              x: 0
            }}
            exit={{
              x: '-100%'
            }}
            transition={{ type: 'tween', bounce: 0, duration: 0.4 }}
          >
            {children}
          </SidekickBody>
        </StyledDrawer>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
