import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledSwitch = styled.button`
  display: flex;
  align-items: center;
  flex-grow: 0;
  justify-content: center;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
`;
