import styled from 'styled-components';

import { themeGet } from '@styled-system/theme-get';

export const StyledMobileWrapper = styled.div`
  background-color: ${themeGet('colors.body.bg')};
  height: 100%;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${themeGet('colors.primary.light')};
  padding: 14px 20px;
  margin: 0 auto;
`;

export const StyledLinkButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.text.default')};
  cursor: pointer;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${themeGet('colors.primary.light')};
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 50px;
`;

export const StyledActionButton = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledAuthButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px 17px;
  color: ${themeGet('colors.text.default')};
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-right: 11px;
  }
`;
