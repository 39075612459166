import { createContext, useContext, useMemo, useReducer } from 'react';

import NotificationPopup from 'components/notification-popup/notification-popup';

const initialState = {
  showFlash: false,
  message: '',
  options: {
    status: 'error',
    autoCloseTimeout: 3000,
    closeOnClick: false
  }
};

const NotificationContext = createContext(initialState);

NotificationContext.displayName = 'NotificationContext';

const notificationReducer = (s, v) => ({ ...s, ...v });

export const NotificationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);
  const { showFlash, options } = state;
  const { autoCloseTimeout } = options;

  const hideNotify = () => dispatch({ showFlash: false, message: '' });

  const setMessage = (message = '', opt = {}) => {
    const settings = { ...options, ...opt };
    if (!showFlash) {
      dispatch({
        showFlash: true,
        message,
        options: settings
      });

      if (!settings.closeOnClick) {
        setTimeout(() => hideNotify(), autoCloseTimeout);
      }
    }
  };

  const setMessageError = (err, message = false) => {
    return message
      ? setMessage(err.message, {
          status: 'error'
        })
      : err.text().then((text) => {
          if (text) {
            const result = JSON.parse(text);
            if (result?.error) {
              setMessage(result?.error, {
                status: 'error'
              });
            }
          }
        });
  };

  const value = useMemo(
    () => ({
      ...state,
      setMessage,
      hideNotify,
      setMessageError
    }),
    [state]
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <NotificationPopup />
    </NotificationContext.Provider>
  );
};

export const useNotify = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('Use NotificationContext inside its provider.');
  }
  return context;
};
