import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ToggleContainer = styled.div`
  flex-shrink: 0;
  padding: 2px;
  border-radius: 90px;
  background-color: ${themeGet('colors.primary.light')};
  transition: all 0.25s ease;
`;

export const ToggleFlex = styled.div`
  display: flex;
  flex-shrink: 0;
  gap: 2px;
`;

export const Text = styled.span`
  font-size: 11px;
  padding: 4px 14px;
  cursor: pointer;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.text.regular')};
  transition: all 0.25s ease;
  border-radius: 90px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledToggle = styled.div`
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${Text} {
    &.option--active {
      cursor: default;
      color: ${themeGet('colors.text.light')};
      background-color: ${themeGet('colors.primary.regular')};
    }
  }
`;

export const StyledActivateText = styled.p`
  font-size: ${themeGet('fontSizes.xs')}px;
`;

export const InActivateText = styled.p`
  color: ${themeGet('colors.text.regular')};
  font-size: ${themeGet('fontSizes.xs')}px;
`;
