import { useTranslation } from 'react-i18next';
import { BannerBlock } from './banner.style';
import ExclamationSign from '../../components/icons/ExclamationSign';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <BannerBlock>
      <ExclamationSign />
      {t('announcement.info')}
    </BannerBlock>
  )
}

export default Banner;
