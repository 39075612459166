import styled from 'styled-components';

import { Row } from 'assets/styles/pages.style';

export const StyledModalTableWrapper = styled(Row)`
  height: inherit;
  margin-top: -40px;
  margin-bottom: 0;
`;

export const StyledPositionedRow = styled.div`
  position: relative;
  height: inherit;
  margin-bottom: 0;
`;

export const StyledOverflow = styled.div`
  overflow: auto;
  height: inherit;
  scroll-behavior: smooth;
`;
