import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Row } from 'assets/styles/pages.style';

export const StyledTableWrapper = styled.div`
  border: none;
`;

export const StyledTableScroll = styled.div`
  padding-bottom: 10px;
`;

export const StyledModalTableWrapper = styled(Row)`
  height: inherit;
  margin-top: -40px;
`;

export const StyledLink = styled.span`
  > span {
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.regular')};
  }
`;

export const StyledLabel = styled.span`
  display: inline-block;
  margin-left: 4px;
  padding: 4px 5px;
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.regular')};
  text-transform: lowercase;
  color: #9cabd6;
  border-radius: 4px;
  line-height: 1;
  border: 1px solid #9cabd6;
`;

export const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 40px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  p {
    color: ${themeGet('colors.text.regular')};
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const StyledHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  > div {
    margin-bottom: 0;
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: inherit;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledTable = styled.div`
  position: relative;

  svg {
    display: block;
  }

  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding-top: 20px;
  }
`;

export const StyledTableCell = styled.div`
  //display: flex;
  //align-items: center;
  border: none;
  white-space: nowrap;
  line-height: 19px;

  &:first-child {
    border-radius: ${themeGet('radii.base')} 0 0 ${themeGet('radii.base')};
  }

  &:last-child {
    border-radius: 0 ${themeGet('radii.base')} ${themeGet('radii.base')} 0;
  }
`;

export const StyledTableColumnTitle = styled.div`
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.primary.regular')};

  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

export const StyledValue = styled.div`
  font-size: ${themeGet('fontSizes.sm')}px;

  b {
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const StyledValueInfo = styled.span`
  font-weight: ${themeGet('fontWeights.regular')};
`;

export const StyledSubValue = styled.span`
  font-size: ${themeGet('fontSizes.sm')}px;
`;

export const StyledSubValueInfo = styled.span`
  font-size: ${themeGet('fontSizes.sm')}px;
`;

export const StyledTableHead = styled.header`
  border-radius: ${themeGet('radii.base')};

  ${StyledTableCell} {
    padding: 26px 20px 40px;
    background-color: ${themeGet('colors.primary.light')};

    ${StyledTableCell} {
      padding: 0;
    }
  }

  ${StyledValue} {
    font-size: ${themeGet('fontSizes.md')}px;
    font-weight: ${themeGet('fontWeights.heading')};

    ${StyledSubValue},
    ${StyledSubValueInfo} {
      font-size: ${themeGet('fontSizes.md')}px;
      font-weight: ${themeGet('fontWeights.heading')};
    }
  }

  ${StyledSubValue} {
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.heading')};
  }

  ${StyledSubValueInfo} {
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.regular')};
  }

  ${StyledLabel} {
    display: none;
  }
`;

export const StyledOverflow = styled.div`
  scroll-behavior: smooth;
  transform: rotateX(180deg);

  > * {
    transform: rotateX(180deg);
  }
`;

export const StyledTableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => props.columns || 5},
    minmax(200px, 1fr)
  );

  &.mt {
    grid-template-columns:
      minmax(200px, 23%) minmax(200px, 21%) minmax(220px, 34%)
      minmax(200px, 10%) minmax(200px, 12%);
  }

  &:nth-child(even) {
    ${StyledTableCell} {
      background-color: ${themeGet('colors.primary.light')};
    }
  }
`;

export const StyledTableBody = styled.div`
  ${StyledTableRow} {
    &.table-grid--every {
      cursor: pointer;
      margin-top: 10px;

      ${StyledTableCell} {
        background-color: ${themeGet('colors.primary.light')};
      }
    }
  }

  ${StyledTableCell} {
    padding: 20px;
  }
`;

export const StyledSkeletonList = styled.ul`
  display: grid;
  grid-gap: 80px;

  @media ${themeGet('mediaQueries.mobileOnly')} {
    grid-gap: 40px;
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${themeGet('mediaQueries.mobileOnly')} {
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${themeGet('mediaQueries.desktopUp')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const StyledSkeletonItem = styled.li`
  list-style: none;
`;
