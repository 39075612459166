import { useTranslation } from 'react-i18next';
import { padTo2Digits } from 'utils/helpers/converters';

export default function useDateFormat() {
  const { t } = useTranslation();

  const dateFullMonth = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getUTCFullYear();
    const dt = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const monthLetter = t(`months.${month}`);
    return `${padTo2Digits(dt)} ${monthLetter}, ${year}`;
  };

  const dateUTCFullTime = (dateTime) => {
    const date = new Date(dateTime);
    return `${[
      padTo2Digits(date.getUTCHours()),
      padTo2Digits(date.getUTCMinutes()),
      padTo2Digits(date.getUTCSeconds())
    ].join(':')}`;
  };

  return { dateFullMonth, dateUTCFullTime };
}
