import { gql } from '@apollo/client';

export const GET_USER = gql`
  query {
    user {
      id
      referrerId
      userName
      email
      security {
        twoFA {
          active
        }
      }
      referral {
        active
        manager
        code
      }
      balances {
        coinId
        incomes
        expenses
      }
      wallets {
        id
        coinId
        networkType
        name
        address
        active
        createdAt
        autoWithdraw {
          type
          fixedAmount
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query ($coinId: ObjectID!) {
    userInfo(coinId: $coinId) {
      coinId
      lastShare
      hashRate
      shares
      sharesOk
      activeWorkers
      inactiveWorkers
      blocks
      reward
    }
  }
`;

export const GET_USER_STATISTICS_BY_DAY = gql`
  query ($coinId: ObjectID!, $fromDate: Time!, $toDate: Time!) {
    statisticsByDay(coinId: $coinId, fromDate: $fromDate, toDate: $toDate) {
      blockCount
      reward
      hashRate
      luck
      prices
      dateTime
    }
  }
`;

export const GET_USER_STATISTICS_BY_HOUR = gql`
  query ($coinId: ObjectID!, $date: Time!) {
    statisticsByHour(coinId: $coinId, date: $date) {
      blockCount
      reward
      hashRate
      luck
      prices
      dateTime
    }
  }
`;

export const GET_USER_HASHRATE = gql`
  query ($coinId: ObjectID!) {
    hashRate(coinId: $coinId) {
      hashRate
      dateTime
    }
  }
`;

export const GET_USER_WORKERS = gql`
  query ($coinId: ObjectID!) {
    workers(coinId: $coinId) {
      workerId
      active
      lastShare
      hashRate
      shares
      sharesOk
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query ($coinId: ObjectID!, $pageNumber: Uint64!, $pageSize: Uint64!) {
    transactions(
      coinId: $coinId
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      id
      coinId
      networkType
      type
      status
      amount
      createdAt
      updatedAt
      networkInfo {
        blockNumber
        blockHash
        transactionHash
        fromAddress
        toAddress
        fee
      }
    }
  }
`;

export const GET_USER_TELEGRAM_BOT = gql`
  query {
    userTelegramBot
  }
`;
