import { differenceInMonths, isValid } from 'date-fns';

export const toFixed = (num, decimals = 5) =>
  Math.floor(num * 10 ** decimals) / 10 ** decimals;

export const bigIntToPercent = (num) => (num / 10000) * 100;

export const valuesToPercent = (num) =>
  toFixed(Math.floor(Number(num) * 10) / 10 / 100);

export const percentToBigInt = (num) => (num * 10000).toString();

export const bigIntToFloat = (num, decimals = 18) => num / 10 ** decimals;

export const floatToBigInt = (num, decimals = 18) =>
  (Number(num) * 10 ** decimals).toString();

export const getBalance = (balance) => {
  const result = Number(balance?.incomes) - Number(balance?.expenses) || 0;
  return toFixed(bigIntToFloat(result));
};

export const truncateName = (str, number = 10) => {
  return `${str.substr(0, number)}...${str.substr(-number)}`;
};

export const getPrice = (
  balance,
  prices = { EUR: 0, USD: 0 },
  currency = 'USD'
) => {
  if (Object.keys(prices).length === 0 || !balance) return 0;
  return (balance * prices[currency]).toFixed(2);
};

export function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const normalizeDate = (dateTime) => {
  const date = new Date(dateTime);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const dt = date.getUTCDate();

  return `${year}-${padTo2Digits(month)}-${padTo2Digits(dt)}`;
};

export const checkLastShare = (dateTime, t) => {
  const date = new Date(dateTime);
  const result = differenceInMonths(date, new Date());

  if (!isValid(date)) {
    return 0;
  }

  if (result < 0) {
    return t('wallet.moreThan');
  }

  return normalizeDate(dateTime);
};

export const formatTime = (date) => {
  return `${[
    padTo2Digits(date.getUTCHours()),
    padTo2Digits(date.getUTCMinutes())
  ].join(':')}`;
};

export const dateFullTime = (dateTime) => {
  const date = new Date(dateTime);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const dt = date.getUTCDate();

  return `${year}-${padTo2Digits(month)}-${padTo2Digits(dt)} ${formatTime(
    date
  )}`;
};

export const sumHelper = (arr, field = 'reward') => {
  return arr.reduce((sum, a) => sum + Number(a[field]), 0);
};

export const averageHelper = (arr, field = 'val') => {
  return sumHelper(arr, field) / arr.length;
};

export const handleParseNumber = (num) => {
  if (num >= 1000000000000000000000) {
    return {
      val: (num / 1000000000000000000000).toFixed(3).replace(/\.0$/, ''),
      metric: 'ZH/s'
    };
  }
  if (num >= 1000000000000000000) {
    return {
      val: (num / 1000000000000000000).toFixed(3).replace(/\.0$/, ''),
      metric: 'EH/s'
    };
  }
  if (num >= 1000000000000000) {
    return {
      val: (num / 1000000000000000).toFixed(3).replace(/\.0$/, ''),
      metric: 'PH/s'
    };
  }
  if (num >= 1000000000000) {
    return {
      val: (num / 1000000000000).toFixed(3).replace(/\.0$/, ''),
      metric: 'TH/s'
    };
  }
  if (num >= 1000000000) {
    return {
      val: (num / 1000000000).toFixed(3).replace(/\.0$/, ''),
      metric: 'GH/s'
    };
  }
  if (num >= 1000000) {
    return {
      val: (num / 1000000).toFixed(3).replace(/\.0$/, ''),
      metric: 'MH/s'
    };
  }
  if (num >= 1000) {
    return {
      val: (num / 1000).toFixed(3).replace(/\.0$/, ''),
      metric: 'KH/s'
    };
  }
  if (num > 0) {
    return {
      val: parseFloat(num).toFixed(3).replace(/\.0$/, ''),
      metric: 'H/s'
    };
  }
  return {
    val: 0,
    metric: 'H/s'
  };
};
