import { HeadingWrapper, HeadingText } from './heading.style';

const Heading = ({
  justifyContent = 'flex-start',
  variant: Variant,
  children,
  mb = 5,
  component,
  subheading = ''
}) => {
  return (
    <HeadingWrapper mb={[3, 4, 4, mb]} justifyContent={justifyContent}>
      <Variant className="variant-heading">
        {children}
        {subheading && (
          <HeadingText dangerouslySetInnerHTML={{ __html: subheading }} />
        )}
      </Variant>
      {component && component}
    </HeadingWrapper>
  );
};

export default Heading;
