import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ListItemText = styled.span`
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.base')}px;
  color: ${themeGet('colors.text.default')};
  line-height: ${themeGet('lineHeights.body')};
  font-weight: ${themeGet('fontWeights.bold')};
`;

export const ListItem = styled.li`
  &.active {
    ${ListItemText} {
      background-color: ${themeGet('colors.primary.light')};
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const List = styled.ul`
  list-style: none;

  &.popover {
    ${ListItemText} {
      font-weight: ${themeGet('fontWeights.regular')};
    }

    ${ListItem} {
      &.active {
        ${ListItemText} {
          font-weight: ${themeGet('fontWeights.bold')};
        }
      }
    }
  }

  &.list {
    ${ListItem} {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      ${ListItemText} {
        padding: 4px 23px;
      }

      &.active {
        ${ListItemText} {
          border-radius: ${themeGet('radii.base')};
          background-color: ${themeGet('colors.primary.light')};
          color: ${themeGet('text.regular')};
          font-weight: ${themeGet('fontWeights.bold')};
        }
      }
    }
  }
`;
