const ExclamationSign = () => (
  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.37402 24H24.6258C26.799 24 28.1537 21.6434 27.0671 19.7666L16.4412 1.40761C15.3546 -0.469205 12.6452 -0.469205 11.5586 1.40761L0.932748 19.7666C-0.153831 21.6434 1.20086 24 3.37402 24ZM13.9999 14.122C13.2238 14.122 12.5888 13.487 12.5888 12.7109V9.88858C12.5888 9.11245 13.2238 8.47744 13.9999 8.47744C14.7761 8.47744 15.4111 9.11245 15.4111 9.88858V12.7109C15.4111 13.487 14.7761 14.122 13.9999 14.122ZM15.4111 18.3554C15.4111 19.1348 14.7793 19.7666 13.9999 19.7666C13.2206 19.7666 12.5888 19.1348 12.5888 18.3554C12.5888 17.5761 13.2206 16.9443 13.9999 16.9443C14.7793 16.9443 15.4111 17.5761 15.4111 18.3554Z"
      fill="#FFF36C"
    />
  </svg>
);

export default ExclamationSign;
