export const DASHBOARD_PAGE = '/';
export const STATS_NOAUTH_PAGE = '/minerstats';
export const WALLETS_AUTH_PAGE = '/wallets';
export const WALLETS_STATS_AUTH_PAGE = '/wallets/statistics';
export const WALLETS_WITHD_AUTH_PAGE = '/wallets/withdrawals';
export const REFERRALS_SETTINGS_PAGE = '/referrals/settings';
export const REFERRALS_STATS_PAGE = '/referrals/statistics';
export const REFERRALS_ACTIVITY_PAGE = '/referrals/activity';
export const MINER_SETTING_PAGE = '/miner-setting';
export const LUCK_PAGE = '/luck';
export const FAQ_PAGE = '/faq';
export const ACCOUNT_PAGE = '/account';
export const AUTH_LOGIN_PAGE = '/auth/login';

export const MENU_ITEMS = [
  {
    id: 'menu.dashboard',
    href: DASHBOARD_PAGE
  },
  {
    id: 'menu.minerstats',
    href: STATS_NOAUTH_PAGE
  },
  {
    id: 'menu.luck',
    href: LUCK_PAGE
  },
  {
    id: 'menu.minersetting',
    href: MINER_SETTING_PAGE
  },
  {
    id: 'menu.faq',
    href: FAQ_PAGE
  }
];

export const AUTHORIZED_MENU_ITEMS = [
  {
    id: 'menu.dashboard',
    href: DASHBOARD_PAGE
  },
  {
    id: 'menu.minerstats',
    href: WALLETS_STATS_AUTH_PAGE
  },
  {
    id: 'menu.luck',
    href: LUCK_PAGE
  },
  {
    id: 'menu.minersetting',
    href: MINER_SETTING_PAGE
  },
  {
    id: 'menu.faq',
    href: FAQ_PAGE
  },
  {
    id: 'menu.account',
    href: ACCOUNT_PAGE
  }
];

export const AUTHORIZED_WALLETS_TABS_ITEMS = [
  {
    id: 'wallet.tabs.statistics',
    href: WALLETS_STATS_AUTH_PAGE
  },
  {
    id: 'wallet.tabs.withdrawals',
    href: WALLETS_WITHD_AUTH_PAGE
  },
  {
    id: 'wallet.tabs.wallets',
    href: WALLETS_AUTH_PAGE
  }
];

export const REFERRALS_TABS_ITEMS = [
  {
    id: 'pageReferrals.tabsItems.settings',
    href: REFERRALS_SETTINGS_PAGE
  },
  {
    id: 'pageReferrals.tabsItems.statistics',
    href: REFERRALS_STATS_PAGE
  },
  {
    id: 'pageReferrals.tabsItems.activity',
    href: REFERRALS_ACTIVITY_PAGE
  }
];
