import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ModalWrapper = styled.div`
  .modal-root {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.white};
    z-index: 50;

    .modal-close {
      position: absolute;
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: ${themeGet('colors.body.light')};
      color: ${themeGet('colors.body.text')};
      border-radius: ${themeGet('radii.base')};
      right: 80px;
      top: 20px;
      border: none;
      outline: none;
      line-height: 0;
    }
  }

  &.secondary {
    .modal-root {
      .modal-close {
        z-index: 5;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
      }
    }
  }

  .modal-motion {
    position: relative;
    width: 100%;
    height: 100%;

    .modal-full {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .modal-overflow {
        width: 100%;
        height: 100%;
      }

      &.half-full {
        width: inherit;
        height: 80%;
      }
    }

    .modal-full__width {
      width: 100%;
    }
  }
`;
