import { TableHead } from 'components/table-block/table-elements';

import { columnsStatistics } from 'utils/columns-table';
import {
  bigIntToFloat,
  toFixed,
  handleParseNumber,
  getPrice
} from 'utils/helpers/converters';
import { useDataContext } from 'contexts/data/data.context';
import useDateFormat from 'utils/hooks/use-date-format';

import {
  StyledTableRow,
  StyledTableBody,
  StyledTable,
  StyledTableCell,
  StyledValue,
  StyledValueInfo
} from 'components/table-block/table-block.style';

export const DailyStatisticModalTableRow = ({ row }) => {
  const { activeCoin } = useDataContext();
  const { dateUTCFullTime } = useDateFormat();
  const parseNumber = handleParseNumber(row?.hashRate);

  return (
    <StyledTableRow columns={5}>
      <StyledTableCell>
        <StyledValue>{dateUTCFullTime(row?.dateTime)}</StyledValue>
      </StyledTableCell>
      <StyledTableCell>
        <StyledValue>
          <StyledValueInfo>$</StyledValueInfo>{' '}
          {getPrice(bigIntToFloat(row?.reward), row?.prices)} /{' '}
          {toFixed(bigIntToFloat(row?.reward))} {activeCoin?.tag.toUpperCase()}
        </StyledValue>
      </StyledTableCell>
      <StyledTableCell>
        <StyledValue>{`${parseNumber?.val} ${parseNumber?.metric}`}</StyledValue>
      </StyledTableCell>
      <StyledTableCell>
        <StyledValue>{`${Math.floor(row?.luck * 100)} %`}</StyledValue>
      </StyledTableCell>
      <StyledTableCell>
        <StyledValue>{row?.blockCount}</StyledValue>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const DailyStatisticModalTable = ({ rows }) => {
  const { activeCoin } = useDataContext();
  return (
    <StyledTable>
      {rows.length > 0 && (
        <TableHead
          columns={columnsStatistics}
          activeCoin={activeCoin}
          row={rows[0]}
        />
      )}

      <StyledTableBody>
        {rows.map((row) => (
          <DailyStatisticModalTableRow key={row.dateTime} row={row} />
        ))}
      </StyledTableBody>
    </StyledTable>
  );
};
