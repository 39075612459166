import { useTranslation } from 'react-i18next';

import { useNormalizeCell } from './use-normalize-cell';

import {
  StyledTableRow,
  StyledTableCell,
  StyledValue,
  StyledTableHead,
  StyledTableColumnTitle
} from './table-block.style';

export const TableHead = ({ columns, row, activeCoin, cssClass }) => {
  const { t } = useTranslation();

  if (row.length === 0) return null;

  return (
    <StyledTableHead>
      <StyledTableRow columns={columns.length} className={cssClass}>
        {columns.map(({ title, dataIndex }) => (
          <StyledTableCell key={dataIndex}>
            <StyledTableColumnTitle>{t(title)}</StyledTableColumnTitle>
            <TableCell
              item={row}
              dataIndex={dataIndex}
              activeCoin={activeCoin}
              isHead
            />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </StyledTableHead>
  );
};

export const TableRow = ({ columns, row, activeCoin, cssClass }) => {
  return (
    <StyledTableRow columns={columns.length} className={cssClass}>
      {columns.map(({ dataIndex }) => (
        <TableCell
          key={dataIndex}
          item={row}
          dataIndex={dataIndex}
          activeCoin={activeCoin}
        />
      ))}
    </StyledTableRow>
  );
};

export const TableCell = (props) => {
  const { cellValue } = useNormalizeCell(props);
  return (
    <StyledTableCell>
      <StyledValue>{cellValue}</StyledValue>
    </StyledTableCell>
  );
};
