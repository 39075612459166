import { memo, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { isEmpty, isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useWallet } from 'contexts/wallet/wallet.context';
import { Button } from 'components/common/button/button';
import BaseModal from './base-modal';

const DataModal = ({
  name,
  event,
  loading = false,
  onEdit,
  onCreate,
  children,
  editModeFunc
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);

  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const { emitter } = useWallet();

  const {
    isValid,
    values,
    touched,
    setValues,
    resetForm,
    validateForm,
    setTouched,
    setSubmitting
  } = useFormikContext();

  useEffect(() => {
    const unbind = emitter.on(event, (payload) => {
      setOpen(true);
      setData(payload);
    });
    return () => unbind();
  }, [emitter, event]);

  useEffect(() => {
    data && setValues(data) && setEditMode(true);
  }, [data]);

  useEffect(() => {
    if (isFunction(editModeFunc)) {
      editModeFunc(isEditMode);
    }
  }, [editModeFunc, isEditMode]);

  const onSubmit = async (newData) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      if (isEditMode) {
        if (data !== newData) {
          if (isFunction(onEdit)) {
            onEdit(newData, isEditMode);
          }
        }
      } else {
        if (isFunction(onCreate)) {
          onCreate(newData);
        }
      }
      modalRef.current.handleClose();
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  const onDestroy = () => {
    resetForm();
    setEditMode(false);
    setData(null);
  };

  const getTitle = (editMode, type) => {
    return editMode
      ? type === 'wallet'
        ? t('dataModal.save')
        : t('dataModal.editWithdrawal')
      : type === 'wallet'
      ? t('dataModal.addWallet')
      : t('dataModal.addWithdrawal');
  };

  const submitAction = (
    <Button
      loading={loading}
      disabled={!isValid}
      onClick={() => onSubmit(values)}
      type="submit"
    >
      {getTitle(isEditMode, name)}
    </Button>
  );

  return (
    <BaseModal
      ref={modalRef}
      action={submitAction}
      onDestroy={onDestroy}
      state={[open, setOpen]}
    >
      {children(data !== null ? data?.id : null)}
    </BaseModal>
  );
};

export default memo(DataModal);
