import { useEffect, memo } from 'react';
import { Form, useFormikContext } from 'formik';
import { isEmpty, omit, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
  CREATE_WITHDRAW,
  CREATE_AUTO_WITHDRAW,
  UPDATE_AUTO_WITHDRAW
} from 'graphql/withdraw.mutation';

import { floatToBigInt } from 'utils/helpers/converters';
import { useWallet } from 'contexts/wallet/wallet.context';

import { Actions, CloseButton } from 'assets/styles/form.style';
import { Button } from 'components/common/button/button';
import CloseIcon from 'components/icons/CloseIcon';

const DataModalToggler = ({ name, data, mode, children, handleClose }) => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = mode;
  const { emitter } = useWallet();

  const {
    isValid,
    values,
    touched,
    setValues,
    validateForm,
    setTouched,
    setSubmitting
  } = useFormikContext();

  useEffect(() => {
    data !== null && setValues(data) && setEditMode(true);
  }, [data]);

  const [createWithdraw, { loading }] = useMutation(CREATE_WITHDRAW);

  const [createAutoWithdraw, { loading: autoLoading }] =
    useMutation(CREATE_AUTO_WITHDRAW);

  const [updateAutoWithdraw, { loading: updateAutoLoading }] =
    useMutation(UPDATE_AUTO_WITHDRAW);

  const addWithdrawal = async (values) => {
    const modifyValues = omit(values, ['coinId', 'active']);
    modifyValues.amount = floatToBigInt(values.amount);

    const result = await createWithdraw({
      variables: {
        input: modifyValues
      },
      context: { clientName: 'private' }
    });

    if (result?.data?.createWithdraw) {
      emitter.emit('reload_transactions');
      emitter.emit('reload_user');
    }
  };

  const addAutoWithdrawal = async (values) => {
    const modifyValues = omit(values, ['coinId']);

    if (values.autoWithdraw.type === 'hour') {
      modifyValues.autoWithdraw.fixedAmount = floatToBigInt(
        values.autoWithdraw.fixedAmount
      );
    }

    createAutoWithdraw({
      variables: {
        walletId: modifyValues.id,
        input: { ...modifyValues.autoWithdraw }
      },
      context: { clientName: 'private' }
    });
  };

  const editHandler = async (values) => {
    const modifyValues = pick(values, [
      'autoWithdraw.type',
      'autoWithdraw.fixedAmount'
    ]);

    if (values.autoWithdraw.type === 'hour') {
      modifyValues.autoWithdraw.fixedAmount = floatToBigInt(
        values.autoWithdraw.fixedAmount
      );
    }

    const result = await updateAutoWithdraw({
      variables: {
        walletId: values.id,
        input: { ...modifyValues.autoWithdraw }
      },
      context: { clientName: 'private' }
    });
    if (result?.data?.updateAutoWithdraw) {
    }
  };

  const onSubmit = async (newData) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      if (isEditMode) {
        if (data !== newData) {
          editHandler(newData);
        }
      } else {
        name === 'manual' ? addWithdrawal(newData) : addAutoWithdrawal(newData);
      }
      handleClose.handleClose();
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  const getTitle = (editMode) => {
    return editMode
      ? t('dataModal.editWithdrawal')
      : t('dataModal.addWithdrawal');
  };

  const submitAction = (
    <Button
      loading={
        name === 'manual'
          ? loading
          : isEditMode
          ? updateAutoLoading
          : autoLoading
      }
      disabled={!isValid}
      onClick={() => onSubmit(values)}
      type="submit"
    >
      {getTitle(isEditMode)}
    </Button>
  );

  return (
    <Form noValidate>
      {children}
      <Actions>
        {submitAction}
        <CloseButton type="button" onClick={() => handleClose.handleClose()}>
          <CloseIcon />
        </CloseButton>
      </Actions>
    </Form>
  );
};

export default memo(DataModalToggler);
