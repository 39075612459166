exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account/[...].js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-pass-reset-js": () => import("./../../../src/pages/auth/pass-reset.js" /* webpackChunkName: "component---src-pages-auth-pass-reset-js" */),
  "component---src-pages-auth-register-js": () => import("./../../../src/pages/auth/register.js" /* webpackChunkName: "component---src-pages-auth-register-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-luck-js": () => import("./../../../src/pages/luck.js" /* webpackChunkName: "component---src-pages-luck-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-miner-setting-js": () => import("./../../../src/pages/miner-setting.js" /* webpackChunkName: "component---src-pages-miner-setting-js" */),
  "component---src-pages-minerstats-[address]-js": () => import("./../../../src/pages/minerstats/[address].js" /* webpackChunkName: "component---src-pages-minerstats-[address]-js" */),
  "component---src-pages-minerstats-index-js": () => import("./../../../src/pages/minerstats/index.js" /* webpackChunkName: "component---src-pages-minerstats-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-referrals-js": () => import("./../../../src/pages/referrals/[...].js" /* webpackChunkName: "component---src-pages-referrals-js" */),
  "component---src-pages-staking-js": () => import("./../../../src/pages/staking.js" /* webpackChunkName: "component---src-pages-staking-js" */),
  "component---src-pages-wallets-js": () => import("./../../../src/pages/wallets/[...].js" /* webpackChunkName: "component---src-pages-wallets-js" */)
}

