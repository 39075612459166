const LinkedinIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM8.48034 9.93888H5.76087V18.1097H8.48034V9.93888ZM8.65928 7.41135C8.64163 6.6102 8.06873 6 7.13842 6C6.20811 6 5.5999 6.6102 5.5999 7.41135C5.5999 8.1959 6.19013 8.82367 7.10312 8.82367H7.1205C8.06873 8.82367 8.65928 8.1959 8.65928 7.41135ZM18.2879 13.4248C18.2879 10.9151 16.9463 9.74704 15.1568 9.74704C13.7129 9.74704 13.0665 10.5401 12.7055 11.0965V9.93913H9.98575C10.0216 10.7058 9.98575 18.11 9.98575 18.11H12.7055V13.5467C12.7055 13.3025 12.7232 13.0589 12.7951 12.8841C12.9916 12.3962 13.4391 11.8911 14.1904 11.8911C15.1749 11.8911 15.5684 12.6403 15.5684 13.7384V18.1098H18.2878L18.2879 13.4248Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
