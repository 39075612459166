import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Button = styled.button`
  position: absolute;
  right: -60px;
  bottom: 0;

  width: 40px;
  height: 40px;
  padding: 0;

  border-radius: ${themeGet('radii.base')};
  background-color: ${themeGet('colors.primary.light')};

  &:hover {
    background-color: ${themeGet('colors.primary.regular')};
  }
`;
