import { createContext, useReducer, useMemo, useContext } from 'react';

const initialState = {
  dataLoading: false,
  useTwoFa: false,
  displayTwoFaToggler: false,
  displayModal: false,
  displaySidebar: false,
  updateToken: '',
  verifyStatus: null
};

const UIContext = createContext(initialState);

UIContext.displayName = 'UIContext';

function uiReducer(state, action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true
      };
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        dataLoading: action.loading
      };
    }
    case 'WORKERS_MODAL': {
      return {
        ...state,
        modalView: 'WORKERS_MODAL_VIEW'
      };
    }
    case 'DAILY_STAT_MODAL': {
      return {
        ...state,
        modalView: 'DAILY_STAT_VIEW'
      };
    }
    case 'TWO_FA_MODAL': {
      return {
        ...state,
        modalView: 'TWO_FA_MODAL_VIEW'
      };
    }
    case 'RESET_PASS_MODAL': {
      return {
        ...state,
        modalView: 'RESET_PASS_MODAL_VIEW'
      };
    }
    case 'VERIFY_MODAL': {
      return {
        ...state,
        modalView: 'VERIFY_MODAL_VIEW',
        verifyStatus: action.status
      };
    }
    case 'SET_UPDATE_TOKEN': {
      return {
        ...state,
        updateToken: action.token
      };
    }
    case 'SET_ACTIVE_TWO_FA': {
      return {
        ...state,
        displayTwoFaToggler: action.value
      };
    }
    case 'SET_USE_TWO_FA': {
      return {
        ...state,
        useTwoFa: action.value
      };
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false
      };
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view
      };
    }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

export const UIProvider = (props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const showWorkers = () => dispatch({ type: 'WORKERS_MODAL' });
  const showDailyStat = () => dispatch({ type: 'DAILY_STAT_MODAL' });
  const showTwoFAModal = () => dispatch({ type: 'TWO_FA_MODAL' });
  const openResetPassModal = () => dispatch({ type: 'RESET_PASS_MODAL' });
  const openVerifyModal = (status) =>
    dispatch({ type: 'VERIFY_MODAL', status });
  const openSidebar = () => dispatch({ type: 'OPEN_SIDEBAR' });
  const closeSidebar = () => dispatch({ type: 'CLOSE_SIDEBAR' });

  const openModal = () => dispatch({ type: 'OPEN_MODAL' });
  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });

  const setUpdateToken = (token) =>
    dispatch({ type: 'SET_UPDATE_TOKEN', token });

  const setLoadingModal = (loading) =>
    dispatch({ type: 'SET_LOADING', loading });

  const setActiveTwoFaToggler = (value) =>
    dispatch({ type: 'SET_ACTIVE_TWO_FA', value });

  const setUseTwoFa = (value) => dispatch({ type: 'SET_USE_TWO_FA', value });

  const setModalView = (view) => dispatch({ type: 'SET_MODAL_VIEW', view });

  const value = useMemo(
    () => ({
      ...state,
      showWorkers,
      showTwoFAModal,
      openVerifyModal,
      openResetPassModal,
      showDailyStat,
      openSidebar,
      closeSidebar,
      openModal,
      closeModal,
      setLoadingModal,
      setModalView,
      setActiveTwoFaToggler,
      setUseTwoFa,
      setUpdateToken
    }),
    [state]
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};

export const ManagedUIContext = ({ children }) => (
  <UIProvider>{children}</UIProvider>
);
