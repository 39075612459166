const ETCIconNoShadow = ({
  width = 15,
  height = 25,
  color = 'currentColor'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07143 0L7.07134 0.000141227L7.07126 0L7.07109 0.000551849L0.000162773 11.7329L7.07115 15.9126L7.07126 15.9127L7.07129 15.9127L7.07143 15.9128V15.9126L14.1425 11.7329L7.07143 0.000282454V0ZM7.07126 24.0003V23.9991L7.07164 24.0002L14.1472 14.0357L7.07164 18.2134L7.07126 18.2139V18.2134L0 14.0357L7.07126 24.0003Z"
        fill={color}
      />
    </svg>
  );
};

export default ETCIconNoShadow;
