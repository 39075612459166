import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: min(720px, 100vw - 40px);
`;

export const QrContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  margin: 30px 0 40px;
  border: 1px solid ${themeGet('colors.primary.regular')};
  border-radius: ${themeGet('radii.base')};

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    padding: 60px;
    margin: 40px 0 60px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    padding: 50px 30px;
  }
`;

export const QrCodeWrapper = styled.div`
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  background-color: ${themeGet('colors.primary.light')};
  padding: 20px;
  border-radius: ${themeGet('radii.base')};

  /* & button {
    width: 100%;
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    flex-direction: row;
    gap: 40px;

    & button {
      width: 300px;
    }

    & input {
      width: calc(100% - 300px);
    }
  } */
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 20px;

  span {
    font-size: ${themeGet('fontSizes.xs')}px;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: ${themeGet('colors.primary.regular')};

  > p {
    margin-right: 20px;
  }
`;

export const CopyButton = styled.button`
  padding: 0;
  background-color: transparent;
  color: currentColor;
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: ${themeGet('colors.body.light')};
  color: ${themeGet('colors.body.text')};
  border-radius: ${themeGet('radii.base')};
  right: 0;
  top: 0;
  border: none;
  outline: none;
  line-height: 0;

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    right: -150px;
  }
`;
