import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: auto;

  > [tabindex='-1'] {
    display: flex;
    flex: auto;
  }
`;
