import {
  BitcoinTalkIcon,
  BitsMediaIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon
} from 'components/icons/footer';

export const socials = [
  {
    url: 'https://www.facebook.com/minerall.io/',
    icon: <FacebookIcon />
  },
  {
    url: 'https://twitter.com/minerall_io',
    icon: <TwitterIcon />
  },
  {
    url: 'https://www.linkedin.com/company/minerall-io/',
    icon: <LinkedinIcon />
  },
  {
    url: 'https://bitcointalk.org/index.php?topic=3484097',
    icon: <BitcoinTalkIcon />
  },
  {
    url: 'https://forum.bits.media/index.php?/topic/86160-minerallio-%D0%BC%D1%83%D0%BB%D1%8C%D1%82%D0%B8%D0%BF%D1%83%D0%BB-eth-etc-zec-xvg-rvn-btc-bch-dash-mona/',
    icon: <BitsMediaIcon />
  },
  {
    url: 'https://www.instagram.com/explore/tags/minerallio/',
    icon: <InstagramIcon />
  }
];

export const press = [
  {
    item: 'minerSetting',
    url: '../miner-setting'
  },
  {
    item: 'partners',
    url: '../partners'
  },
  {
    item: 'minerallGuild',
    url: '../promo'
  }
];

export const chats = [
  {
    url: 'https://t.me/minerallen',
    title: 'En'
  },
  {
    url: 'https://t.me/minerallru',
    title: 'Ua'
  },
  {
    url: 'https://t.me/minerallcn',
    title: 'Cn'
  }
];

export const products = [
  {
    title: 'telegramBot',
    url: 'https://news.minerall.io/help/telegram-notification-bot/'
  }
];
