export const cn = (options) => {
  options = options || {
    now: '現在',
    was: '%% 前',
    will: '%% 現在起'
  };

  return (int, period, isPast) => {
    if (0 === int) {
      return options.now;
    }

    const pattern = isPast ? options.was : options.will;
    const val = 2 > int ? period : period + 's';

    return pattern.replace(/%%/, int + ' ' + val);
  };
};
