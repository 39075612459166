export function heightCollapse(duration = 0.2) {
  return {
    from: {
      opacity: 0,
      height: 0,
      transition: {
        ease: [0.6, 0.62, 0.23, 0.98],
        duration
      }
    },
    to: {
      opacity: 1,
      height: 'auto',
      transition: {
        ease: [0.6, 0.62, 0.23, 0.98],
        duration
      }
    }
  };
}
