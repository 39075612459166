import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Label = styled.span`
  font-size: ${themeGet('fontSizes.base')}px;
  font-weight: ${themeGet('fontWeights.regular')};
  color: ${themeGet('colors.text.default')};
`;

export const List = styled.ul`
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  display: block;
  width: 100%;
  padding: 18px 20px;
  border-radius: ${themeGet('radii.base')};
  background-color: ${themeGet('colors.primary.light')};
  box-shadow: ${themeGet('shadows.popover')};
`;

export const ListItem = styled.li`
  cursor: pointer;
  padding: 4px 0;
  transition: all 0.25s ease;
  font-weight: ${themeGet('fontWeights.regular')};
  color: ${themeGet('colors.text.default')};
  white-space: nowrap;
  font-size: ${themeGet('fontSizes.base')}px;

  &.active,
  &:hover {
    color: ${themeGet('colors.primary.regular')};
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 18px 20px;

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }

  & + * {
    position: relative;
    z-index: 40;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  background-color: ${themeGet('colors.input.bg')};
  border-radius: ${themeGet('radii.base')};

  &.is--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }

  &.error {
    background-color: ${themeGet('colors.input.bgError')};

    ${Label} {
      color: ${themeGet('colors.input.textError')};
    }
  }

  &.small {
    min-width: 106px;

    ${Options} {
      padding: 4px 15px;
    }

    ${List} {
      padding: 10px;
    }

    ${ListItem} {
      font-size: ${themeGet('fontSizes.xs')}px;
    }

    ${Label} {
      text-transform: uppercase;
      line-height: 2;
      font-size: ${themeGet('fontSizes.xs')}px;
      font-weight: ${themeGet('fontWeights.bold')};
    }
  }

  &:not(.small) {
    ${Options} {
      min-height: 60px;
    }
  }
`;
