import {
  forwardRef,
  memo,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react';

import Portal from '@reach/portal';
import { isFunction } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import { ModalWrapper } from 'components/common/modal/modal.style';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

import CloseIcon from 'components/icons/CloseIcon';
import { fadeInOut } from 'utils/motion/fade-in-out';
import { zoomOutIn } from 'utils/motion/zoom-out-in';
import useOnClickOutside from 'utils/hooks/use-on-click-outside';

import { Action } from 'assets/styles/form.style';

const BaseModal = forwardRef(
  ({ state, action, onDestroy, hideActions = false, children }, ref) => {
    const modalInnerRef = useRef();

    const [open, setOpen] = state;

    const handleClose = () => {
      setOpen(false);

      setTimeout(() => {
        isFunction(onDestroy) && onDestroy();
      }, 250);
    };

    useImperativeHandle(ref, () => ({ handleClose }));

    useOnClickOutside(modalInnerRef, () => handleClose());

    useEffect(() => {
      if (modalInnerRef.current) {
        if (open) {
          disableBodyScroll(modalInnerRef.current);
          document.body.classList.add('modal-open');
        } else {
          enableBodyScroll(modalInnerRef.current);
          document.body.classList.remove('modal-open');
        }
      }
      return () => {
        clearAllBodyScrollLocks();
      };
    }, [open]);

    return (
      <Portal>
        <AnimatePresence>
          {open && (
            <ModalWrapper className="secondary">
              <motion.div
                key="modal"
                initial="from"
                animate="to"
                exit="from"
                variants={fadeInOut(0.25)}
                className="modal-root"
              >
                <motion.div
                  initial="from"
                  animate="to"
                  exit="from"
                  variants={zoomOutIn()}
                  className="modal-motion"
                >
                  <div className="modal-full">
                    <div ref={modalInnerRef} className="modal-overflow">
                      {!hideActions && (
                        <button className="modal-close" onClick={handleClose}>
                          <CloseIcon />
                        </button>
                      )}
                      {children}
                      <Action noGap>{action}</Action>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </ModalWrapper>
          )}
        </AnimatePresence>
      </Portal>
    );
  }
);

export default memo(BaseModal);
