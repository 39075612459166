import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useSortableData from 'utils/hooks/use-sortable-data';
import { Button } from 'components/common/button/button';
import { useScrollbar } from 'utils/hooks/use-scrollbar';
import { useWindowSize } from 'utils/hooks/use-window-size';
import { TableHead, TableFootRow, TableRow } from './grid-table-elements';

import {
  StyledTableWrapper,
  StyledTable,
  StyledTableBody,
  StyledEmpty,
  StyledOverflow
} from './grid-table.style';

const GridTable = ({
  columns,
  loading = false,
  data = [],
  hasMoreValues,
  handleLoadMore,
  tfoot = false,
  sortable = true
}) => {
  const { t } = useTranslation();
  const overflowWrapperRef = useRef(null);
  const { isMobile } = useWindowSize();
  const { items, requestSort, sortConfig } = useSortableData(data);

  useScrollbar(overflowWrapperRef, isMobile);

  if (items.length === 0) {
    return (
      <StyledEmpty>
        <p>{t('transactionsBlock.dontHaveText')}</p>
      </StyledEmpty>
    );
  }

  return (
    <StyledTableWrapper>
      <StyledOverflow ref={overflowWrapperRef}>
        <StyledTable>
          <TableHead
            columns={columns}
            requestSort={requestSort}
            sortConfig={sortConfig}
            sortable={sortable}
          />
          <StyledTableBody>
            {items.map((item) => (
              <TableRow key={item.id} columns={columns} item={item} />
            ))}
            {tfoot && <TableFootRow items={items} columns={columns} />}
          </StyledTableBody>
        </StyledTable>
      </StyledOverflow>

      {items.length > 0 && hasMoreValues && (
        <Button
          variant="secondary"
          loading={loading}
          onClick={handleLoadMore}
          fullwidth
        >
          {t('transactionsBlock.moreButtonText')}
        </Button>
      )}
    </StyledTableWrapper>
  );
};

export default GridTable;
