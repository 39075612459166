import { useRef } from 'react';

import { columnsWorkers } from 'utils/columns-table';
import { useDataContext } from 'contexts/data/data.context';
import { useUI } from 'contexts/ui.context';
import { useScrollbar } from 'utils/hooks/use-scrollbar';
import { useWindowSize } from 'utils/hooks/use-window-size';
import Loader from 'components/common/loader/loader';
import WorkersModalHead from './workers-modal-head/workers-modal-head';
import GridTable from '../grid-table/grid-table';
import ScrollToTopButton from './scroll-button/scroll-button';

import {
  StyledModalTableWrapper,
  StyledPositionedRow,
  StyledOverflow
} from './workers-modal.style';

const WorkersModal = () => {
  const { workersFiltered } = useDataContext();
  const { dataLoading } = useUI();
  const { isMobile } = useWindowSize();
  const modalRef = useRef(null);

  useScrollbar(modalRef, isMobile);

  const handleClick = () => {
    modalRef.current.scrollTop = 0;
  };

  if (dataLoading) return <Loader />;

  return (
    <StyledModalTableWrapper px={[3, 3, 7]}>
      <WorkersModalHead />

      <StyledPositionedRow>
        <StyledOverflow ref={modalRef}>
          <GridTable data={workersFiltered} columns={columnsWorkers} />
        </StyledOverflow>
        <ScrollToTopButton handleClick={handleClick} />
      </StyledPositionedRow>
    </StyledModalTableWrapper>
  );
};

export default WorkersModal;
