import { useState, useEffect } from 'react';

export default function useCopyToClipboard(resetTime) {
  const [copied, setCopied] = useState(false);

  const copy = async (text) => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
  };

  useEffect(() => {
    let timerId;
    if (resetTime && copied) {
      timerId = setTimeout(() => {
        setCopied(false);
      }, resetTime);
    }
    return () => clearTimeout(timerId);
  }, [copied, resetTime]);

  return [copied, copy];
}
