const lightTheme = {
  colors: {
    white: '#fff',
    text: {
      default: '#000',
      regular: '#c1ccec',
      light: '#fff',
      inactive: '#a1afd7',
      additional: '#6979a7'
    },
    body: {
      bg: '#fff',
      text: '#000'
    },
    primary: {
      regular: '#6487ff',
      extra: '#6487ff',
      light: '#f0f4ff',
      hover: '#466be7'
    },
    secondary: {
      regular: '#fff',
      hover: '#fff'
    },
    button: {
      regular: '#6487ff',
      hover: '#7493ff',
      active: '#5a79e6',
      variant: {
        success: {
          regular: '#41c787',
          hover: '#65d29e',
          active: '#3ab379'
        },
        gray: {
          regular: '#c1ccec',
          icon: '#fff'
        }
      }
    },
    success: {
      regular: '#41C787'
    },
    alerts: {
      warning: '#f89c1c',
      error: '#ff4d4d'
    },
    linkColor: '#6487ff',
    input: {
      text: '#000',
      bg: '#f0f4ff',
      border: '#dfe4f6',
      hover: '#6487ff',
      textError: '#ff4d4d',
      bgError: '#ffeded',
      placeholder: '#c1ccec'
    },
    menu: {
      color: '#000',
      hover: '#6487ff'
    },
    tooltip: {
      color: '#4d5d7a'
    },
    skeleton: {
      backgroundColor: '#f0f4ff',
      foregroundColor: '#e4ebfd'
    },
    select: {
      borderColor: '#c1ccec',
      menuBorderColor: '#c1ccec',
      menuBgColor: '#fff',
      focusedBgColor: '#f0f4ff',
      focusedTextColor: '#6487ff'
    }
  },
  shadows: {
    popover: '0 24px 12px rgba(100, 135, 255, 0.25)',
    button: '0 24px 24px rgba(100, 135, 255, 0.2)',
    buttonLight: '0 12px 24px rgba(100, 135, 255, 0.2)',
    section: '0 24px 64px #dfe4f6',
    tooltip: '0px 4px 4px #dfe4f6',
    chart: '0 10px 24px #6487ff'
  }
};

export default lightTheme;
