import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';

import { GET_USER_SETTINGS } from 'graphql/settings.query';

import { bigIntToFloat, getBalance } from 'utils/helpers/converters';

import { ModalEvents } from 'utils/constant';
import { useUser } from 'contexts/user.context';
import { useWallet } from 'contexts/wallet/wallet.context';
import { useCoins } from 'contexts/coins.context';

import {
  FormRow,
  Heading,
  Offer,
  Text,
  Wrapper
} from 'assets/styles/form.style';

import Toggler from 'components/wallets/components/withdrawal-toggler/withdrawal-toggler';
import BaseModalToggler from 'components/wallets/wallet-modals/base-modal-toggler';
import FormAuto from './form-auto';
import FormManual from './form-manual';

let balance = { incomes: 0, expenses: 0 };

const WithdrawalModal = () => {
  const { t } = useTranslation();
  const { isAuthenticated, balances } = useUser();
  const { coins, loading } = useCoins();
  const { emitter } = useWallet();
  const [optionsCoins, setOptionsCoins] = useState([]);
  const [activeCoin, setActiveCoin] = useState(null);
  const [mode, setMode] = useState('manual');
  const [coinId, setCoinId] = useState(
    activeCoin !== null ? activeCoin?.id : ''
  );
  const modalRef = useRef(null);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const [loadUserSettings, { data: settings, loading: loadingSettings }] =
    useLazyQuery(GET_USER_SETTINGS, {
      context: { clientName: 'private' }
    });

  useEffect(() => {
    const unbind = emitter.on(ModalEvents.WITHDRAWAL_MODAL, (payload) => {
      setOpen(true);
      if (payload) {
        setData(payload);
      }
    });
    return () => unbind();
  }, [emitter, ModalEvents.WITHDRAWAL_MODAL]);

  useEffect(() => {
    if (isEditMode) {
      setMode('auto');
    }
  }, [isEditMode]);

  useEffect(() => {
    if (isAuthenticated && !loadingSettings && coinId) {
      loadUserSettings({
        variables: { coinId }
      });
    }
  }, [coinId, isAuthenticated, loadUserSettings, loadingSettings]);

  useEffect(() => {
    if (!loading && coins.length > 0) {
      setActiveCoin(coins[0]);
    }
  }, [coins, loading]);

  useEffect(() => {
    if (settings?.userSettings && coins.length > 0 && coinId) {
      setActiveCoin(coins.find((c) => c.id === coinId));
    }
  }, [coinId, coins, settings?.userSettings]);

  useEffect(() => {
    if (optionsCoins.length === 0 && coins.length > 0) {
      setOptionsCoins(
        coins.map((item) => ({ value: item.id, label: item.tag.toUpperCase() }))
      );
    }
  }, [coins, optionsCoins]);

  if (activeCoin !== null && balances?.length > 0) {
    balance = balances.find((el) => el.coinId === activeCoin?.id);
  }

  const userBalance = getBalance(balance);

  const OfferField = () => {
    return (
      <FormRow>
        <Offer>
          <span>
            {t('withdrawalBlock.modal.fee')} <strong>0%</strong>
          </span>
          {t('withdrawalBlock.modal.minPay')}{' '}
          <strong>
            {bigIntToFloat(
              settings?.userSettings?.withdraw.find(
                (item) => item.networkType === 'native'
              )?.minAmount
            )}{' '}
            {activeCoin?.tag.toUpperCase()}
          </strong>
        </Offer>
      </FormRow>
    );
  };

  const onDestroy = () => {
    setEditMode(false);
    setData(null);
  };

  return (
    <BaseModalToggler
      ref={modalRef}
      onDestroy={onDestroy}
      state={[open, setOpen]}
    >
      <Wrapper>
        <Heading>{t('withdrawalBlock.modal.title')}</Heading>
        <Toggler
          editMode={isEditMode}
          coinId={coinId}
          state={[mode, setMode]}
        />
        <FormRow>
          <Text>
            {t('withdrawalBlock.modal.balance')}{' '}
            <strong>
              {userBalance} {activeCoin?.tag.toUpperCase()}
            </strong>
          </Text>
        </FormRow>
        {!isEditMode ? (
          <>
            {mode === 'manual' && (
              <FormManual
                activeCoin={activeCoin}
                userBalance={userBalance}
                optionsCoins={optionsCoins}
                state={[coinId, setCoinId]}
                mode={[isEditMode, setEditMode]}
                data={data}
                settings={settings?.userSettings}
                handleClose={modalRef.current}
              >
                <OfferField />
              </FormManual>
            )}
            {mode === 'auto' && (
              <FormAuto
                activeCoin={activeCoin}
                optionsCoins={optionsCoins}
                state={[coinId, setCoinId]}
                mode={[isEditMode, setEditMode]}
                data={data}
                settings={settings?.userSettings}
                handleClose={modalRef.current}
              >
                <OfferField />
              </FormAuto>
            )}
          </>
        ) : (
          <FormAuto
            activeCoin={activeCoin}
            optionsCoins={optionsCoins}
            state={[coinId, setCoinId]}
            mode={[isEditMode, setEditMode]}
            data={data}
            settings={settings?.userSettings}
            handleClose={modalRef.current}
          >
            <OfferField />
          </FormAuto>
        )}
      </Wrapper>
    </BaseModalToggler>
  );
};

export default WithdrawalModal;
