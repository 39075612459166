import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { useUser } from 'contexts/user.context';
import { useUI } from 'contexts/ui.context';

import {
  MENU_ITEMS,
  AUTHORIZED_MENU_ITEMS,
  REFERRALS_STATS_PAGE,
  REFERRALS_SETTINGS_PAGE
} from 'site-settings/site-navigation';

import { StyledMainMenu, StyledMenuItem } from './main-menu.style';

const CustomLink = ({ children, ...props }) => {
  return (
    <Link
      getProps={({ location, href }) => {
        const newPathname = location.pathname.split('/').slice(2, 3);
        const mainPath = `/${newPathname[0]}`;
        if (mainPath === '/') return null;
        return href.includes(mainPath) ? { className: 'active' } : null;
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

const MainMenu = ({ type = 'default', isAuthenticated }) => {
  const { t } = useTranslation();
  const { closeSidebar } = useUI();
  const { user } = useUser();

  return (
    <StyledMainMenu className={type}>
      {isAuthenticated ? (
        <>
          {AUTHORIZED_MENU_ITEMS.map((item) => (
            <StyledMenuItem key={item.id}>
              <CustomLink to={item.href} onClick={closeSidebar}>
                {t(item.id)}
              </CustomLink>
            </StyledMenuItem>
          ))}
          {user?.referral?.active && !user?.referral?.manager && (
            <StyledMenuItem>
              <CustomLink to={REFERRALS_STATS_PAGE} onClick={closeSidebar}>
                {t('menu.referrals')}
              </CustomLink>
            </StyledMenuItem>
          )}
          {user?.referral?.active && user?.referral?.manager && (
            <StyledMenuItem>
              <CustomLink to={REFERRALS_SETTINGS_PAGE} onClick={closeSidebar}>
                {t('menu.referrals')}
              </CustomLink>
            </StyledMenuItem>
          )}
        </>
      ) : (
        MENU_ITEMS.map((item) => (
          <StyledMenuItem key={item.id}>
            <CustomLink to={item.href} onClick={closeSidebar}>
              {t(item.id)}
            </CustomLink>
          </StyledMenuItem>
        ))
      )}
    </StyledMainMenu>
  );
};

export default MainMenu;
