import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledMenuItem = styled.li`
  a {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 7px 15px;
    cursor: pointer;
    text-transform: uppercase;
    transition: ${themeGet('customs.transition')};
    font-family: ${themeGet('fonts.menu')};
    font-size: ${themeGet('fontSizes.xs')}px;
    font-weight: ${themeGet('fontWeights.bold')};
    color: ${themeGet('colors.menu.color')};

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &[aria-current],
    &:hover {
      color: ${themeGet('colors.menu.hover')};
    }
  }
`;

export const StyledMainMenu = styled.ul`
  display: flex;
  align-items: center;

  &.vertical {
    flex-direction: column;

    ${StyledMenuItem} {
      margin-bottom: 20px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
`;
