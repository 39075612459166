import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

import Dropdown from 'components/common/form/dropdown/dropdown';
import { Input } from 'components/common/form/input';
import { useWallet } from 'contexts/wallet/wallet.context';
import { useCoins } from 'contexts/coins.context';
import { getFieldProps } from 'utils';
import { bigIntToFloat } from 'utils/helpers/converters';
import { heightCollapse } from 'utils/motion/height-collapse';

import { FormField, LabelError } from 'assets/styles/form.style';

export const FixedAmountField = (props) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { values, setFieldValue, errors, setErrors } = formik;
  const { name, settings, editMode } = props;

  useEffect(() => {
    const minAmount = bigIntToFloat(
      settings?.withdraw.find(
        (item) => item.networkType === values.networkType || 'native'
      )?.minAmount
    );

    if (values.autoWithdraw.type === 'day') {
      setFieldValue(name, '0');
    }

    if (values.autoWithdraw.type === 'hour') {
      if (Math.abs(values.autoWithdraw.fixedAmount) < Number(minAmount)) {
        setErrors({
          ...errors,
          autoWithdraw: {
            fixedAmount: t('walletsBlock.walletFields.amountBigger')
          }
        });
      }
    }
  }, [
    name,
    errors,
    editMode,
    values.autoWithdraw.type,
    values.autoWithdraw.fixedAmount,
    values.networkType,
    settings?.withdraw,
    setFieldValue
  ]);

  return (
    <FormField className="small">
      <Input
        type="text"
        variant="small"
        autocomplete="off"
        autocapitalize="off"
        autocorrect="off"
        inputMode="decimal"
        placeholder={t('walletsBlock.modal.amountPlaceholder')}
        {...props}
      />
      <AnimatePresence>
        {Boolean(formik.errors.autoWithdraw?.fixedAmount) &&
          formik.touched.autoWithdraw?.fixedAmount && (
            <motion.div
              initial="from"
              animate="to"
              exit="from"
              variants={heightCollapse()}
            >
              <LabelError>{formik.errors.autoWithdraw?.fixedAmount}</LabelError>
            </motion.div>
          )}
      </AnimatePresence>
    </FormField>
  );
};

export const NetworkTypeField = (props) => {
  const { editMode, settings } = props;
  const formik = useFormikContext();
  const [networkOptions, setNetworkOptions] = useState([]);
  const { values } = formik;
  const { coins } = useCoins();

  useEffect(() => {
    if (settings?.withdraw) {
      if (coins.find((item) => item.id === values.coinId)?.tag === 'eth') {
        setNetworkOptions(
          settings?.withdraw
            .map((item) => ({
              value: item.networkType,
              label: `dropdownOptions.networkTypes.${item.networkType}`
            }))
            .sort((a, b) => {
              return a.value.localeCompare(b.value);
            })
        );
      } else {
        setNetworkOptions([
          {
            value: 'native',
            label: 'dropdownOptions.networkTypes.native'
          }
        ]);
      }
    }
  }, [settings?.withdraw]);

  return (
    <Dropdown
      name="networkType"
      options={networkOptions}
      disabled={editMode}
      {...getFieldProps(formik, 'networkType')}
    />
  );
};

export const NameField = ({ id }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { wallets } = useWallet();
  const { values, errors, setErrors } = formik;
  let filterWallets = wallets;

  useEffect(() => {
    if (id !== null) {
      filterWallets = wallets.filter((w) => w.id !== id);
    }
    const findWallet = filterWallets.find((w) => w.name === values.name) || {};

    if (Object.keys(findWallet).length > 0) {
      setErrors({
        ...errors,
        name: t('walletsBlock.walletFields.nameAlready')
      });
    }
  }, [errors, id, values.name, wallets]);

  return (
    <>
      <Input
        type="text"
        name="name"
        placeholder={t('walletsBlock.modal.namePlaceholder')}
        {...getFieldProps(formik, 'name')}
        required
      />
      <AnimatePresence>
        {Boolean(formik.errors.name) && formik.touched.name && (
          <motion.div
            initial="from"
            animate="to"
            exit="from"
            variants={heightCollapse()}
          >
            <LabelError>{formik.errors.name}</LabelError>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export const AddressField = ({ id }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { wallets } = useWallet();
  const { values, errors, setErrors } = formik;
  let filterWallets = wallets;

  useEffect(() => {
    if (id !== null) {
      filterWallets = wallets.filter((w) => w.id !== id);
    }
    const findWallet =
      filterWallets.find(
        (w) => w.address === values.address && w.coinId === values.coinId
      ) || {};

    if (Object.keys(findWallet).length > 0) {
      setErrors({
        ...errors,
        address: t('walletsBlock.walletFields.addressValid')
      });
    }
  }, [errors, id, values.address, values.coinId, wallets]);

  return (
    <>
      <Input
        type="text"
        name="address"
        placeholder={t('walletsBlock.modal.addressPlaceholder')}
        {...getFieldProps(formik, 'address')}
        required
      />
      <AnimatePresence>
        {Boolean(formik.errors.address) && formik.touched.address && (
          <motion.div
            initial="from"
            animate="to"
            exit="from"
            variants={heightCollapse()}
          >
            <LabelError>{formik.errors.address}</LabelError>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
