import { useState, useEffect } from 'react';

export const useIsAutoWithdraw = (wallets, coinId) => {
  const [m, setMode] = useState('');

  useEffect(() => {
    const isAutoWithdraw = wallets.filter(
      (w) => w.autoWithdraw !== null && w.coinId === coinId
    );
    if (isAutoWithdraw.length > 0) {
      setMode('manual');
    }
    if (isAutoWithdraw.length === 0) {
      setMode('');
    }
  }, [coinId, wallets]);

  return { [coinId]: m };
};
