import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

const Spinner = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 3px solid ${(props) => props.theme.colors.primary.regular};
  border-top: 3px solid
    ${(props) => (props.color ? props.color : 'transparent')};
  border-radius: 50%;
  transition-property: transform;
  animation-name: ${rotate};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Loader = ({ width = '40px', height = '40px' }) => {
  return (
    <StyledSpinner>
      <Spinner width={width} height={height} />
    </StyledSpinner>
  );
};

export default Loader;
