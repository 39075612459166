const UpIcon = () => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99953 17.0005L6.99953 3.83047L9.87953 6.71047C10.2695 7.10047 10.8995 7.10047 11.2895 6.71047C11.6795 6.32047 11.6795 5.69047 11.2895 5.30047L6.69953 0.710469C6.30953 0.320469 5.67953 0.320468 5.28953 0.710468L0.699532 5.30047C0.309532 5.69047 0.309532 6.32047 0.699532 6.71047C1.08953 7.10047 1.71953 7.10047 2.10953 6.71047L4.99953 3.83047L4.99953 17.0005C4.99953 17.5505 5.44953 18.0005 5.99953 18.0005C6.54953 18.0005 6.99953 17.5505 6.99953 17.0005Z"
        fill="#C1CCEC"
      />
    </svg>
  );
};

export default UpIcon;
