import {
  createContext,
  useEffect,
  memo,
  useMemo,
  useContext,
  useReducer
} from 'react';

import { useLazyQuery } from '@apollo/client';

import { GET_USER } from 'graphql/user.query';
import { GET_USER_SETTINGS } from 'graphql/settings.query';

import { useCoins } from 'contexts/coins.context';
import { getToken } from 'utils/get-token';

const defaultState = {
  user: null,
  userSettings: null,
  token: getToken(),
  twoFA: false,
  balances: [],
  isAuthenticated: !!getToken()
};

const UserContext = createContext();

UserContext.displayName = 'UserContext';

function userReducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return {
        token: action.data?.token,
        twoFA: action.data?.twoFA,
        isAuthenticated: true
      };
    case 'SET_USER_SETTINGS':
      return {
        ...state,
        userSettings: action.data
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      };
    case 'SET_BALANCES':
      return {
        ...state,
        balances: action.balances
      };
    case 'CLEAR_DATA':
      return {
        user: {},
        token: null,
        twoFA: false,
        isAuthenticated: false
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, defaultState);
  const { isAuthenticated } = state;
  const { activeCoin } = useCoins();

  const setData = (data) => dispatch({ type: 'SET_DATA', data });

  const setUser = (user) => dispatch({ type: 'SET_USER', user });

  const setUserSettings = (data) =>
    dispatch({ type: 'SET_USER_SETTINGS', data });

  const clearData = () => dispatch({ type: 'CLEAR_DATA' });

  const setBalances = (balances) =>
    dispatch({ type: 'SET_BALANCES', balances });

  const [loadUser, { data, loading }] = useLazyQuery(GET_USER, {
    context: { clientName: 'private' }
  });

  useEffect(() => {
    if (isAuthenticated) {
      loadUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !loading && data?.user) {
      setUser(data?.user);
      if (data?.user?.balances) {
        setBalances(data?.user?.balances);
      }
    }
  }, [data?.user, isAuthenticated, loading]);

  const [loadUserSettings, { data: settings, loading: loadingSettings }] =
    useLazyQuery(GET_USER_SETTINGS, {
      context: { clientName: 'private' }
    });

  useEffect(() => {
    if (isAuthenticated && activeCoin !== null) {
      loadUserSettings({
        variables: { coinId: activeCoin?.id }
      });
    }
  }, [activeCoin, isAuthenticated, loadUserSettings]);

  useEffect(() => {
    if (!loadingSettings && settings?.userSettings) {
      setUserSettings(settings?.userSettings);
    }
  }, [loadingSettings, settings?.userSettings]);

  const value = useMemo(
    () => ({
      ...state,
      setData,
      setUser,
      clearData,
      setBalances
    }),
    [state]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;

const memoizedProvider = memo(UserProvider);

export { memoizedProvider as UserProvider };

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
