import styled from 'styled-components';
import { layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import { Link as GatsbyLink } from 'gatsby';

export const HeaderWrap = styled.header`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${themeGet('colors.primary.light')};
`;

export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 14px 20px;

  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding: 19px 20px;
  }

  ${layout};
`;

export const ActionButton = styled.button`
  padding: 0;
  background-color: transparent;
`;

export const LinkButton = styled(GatsbyLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.text.default')};
  cursor: pointer;
`;
