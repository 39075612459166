import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';
import css from '@styled-system/css';

export const MainContentArea = styled.main`
  display: flex;
  flex: auto;
  width: 100%;
  padding: 60px 0 120px;
  ${space};
`;

export const Container = styled.div(() =>
  css({
    margin: '0 auto',
    width: '100%',
    padding: '0 20px',
    maxWidth: '1320px'
  })
);

export const Row = styled.div`
  &:not(:last-child) {
    margin-bottom: 80px;
  }

  ${space};
`;

export const Section = styled.section`
  position: relative;
  padding: 40px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  ${space};

  @media (max-width: 991px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
`;

export const SectionTitle = styled.h4`
  &:not(:last-child) {
    margin-bottom: 28px;
  }
`;

export const TitleHolder = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    h2:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .justify-blocks {
    display: flex;
    align-items: center;
    gap: 10px;

    @media ${themeGet('mediaQueries.mobileOnly')} {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const TitleHolderJustify = styled.div``;

export const Table = styled.table`
  border-spacing: 0;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  th,
  td {
    margin: 0;
    padding: 1.25rem;
    line-height: 1.3;
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.regular')};

    :last-child {
      border-right: 0;
    }
  }

  th {
    font-size: ${themeGet('fontSizes.up')}px;
  }

  tr {
    :last-child {
      td {
        @media (min-width: 768px) {
          border-bottom: 0;
        }
      }
    }
  }

  tfoot {
    td {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

export const Paragraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Text = styled.div`
  p {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    gap: 20px;
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    flex-wrap: nowrap;
  }
`;

const maintenanceSizes = {
  1: '320px',
  2: '400px'
};

export const MaintenanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  max-width: ${(props) =>
    props.size ? maintenanceSizes[props.size] : maintenanceSizes[1]};

  > svg {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  h2 {
    color: ${themeGet('colors.primary.regular')};

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  button {
    align-self: center;
  }
`;
