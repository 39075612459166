import { useTranslation } from 'react-i18next';

import { useUI } from 'contexts/ui.context';
import { Button } from 'components/common/button/button';
import { Text } from 'components/common/text/text';

import Heading from 'components/common/heading/heading';
import CloseIcon from 'components/icons/CloseIcon';

import {
  Wrapper,
  Anchor,
  BoldText,
  ButtonWrapper,
  TextContainer,
  SubHeading,
  CloseButton
} from './first-view.style';

const FirstView = ({ loading, handler }) => {
  const { t } = useTranslation();
  const {
    verifyStatus,
    useTwoFa,
    modalView,
    closeModal,
    setActiveTwoFaToggler
  } = useUI();

  const handleDecline = () => {
    closeModal();

    if (
      modalView === 'VERIFY_MODAL_VIEW' ||
      modalView === 'TWO_FA_MODAL_VIEW'
    ) {
      if (useTwoFa !== true) {
        if (verifyStatus) {
          setActiveTwoFaToggler(false);
        } else {
          setActiveTwoFaToggler(true);
        }
      }
    }
  };

  return (
    <Wrapper>
      <CloseButton onClick={handleDecline}>
        <CloseIcon />
      </CloseButton>
      <Heading variant="h3" mb={4}>
        {t('twoFA.title')}
      </Heading>
      <SubHeading color="text.additional">{t('twoFA.subTitle')}</SubHeading>
      <Anchor
        to="https://apps.apple.com/ru/app/google-authenticator/id388497605"
        target="_blank"
      >
        {t('twoFA.iosLink')}
      </Anchor>
      <Anchor
        to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=uk&gl=US"
        target="_blank"
      >
        {t('twoFA.androidLink')}
      </Anchor>

      <TextContainer>
        <BoldText>{t('twoFA.confirm')}</BoldText>
        <span>{t('twoFA.confirmText')}</span>
        <BoldText>{t('twoFA.confirmLoose')}</BoldText>
      </TextContainer>

      <Text size="md">{t('twoFA.confirm2FA')}</Text>

      <ButtonWrapper>
        <Button ml={2} loading={loading} onClick={handler}>
          {t('twoFA.accept')}
        </Button>
        <Button variant="gray" onClick={handleDecline}>
          {t('twoFA.decline')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default FirstView;
