import { get } from 'lodash';

export const getStyleString = (value, property, unit = '') => {
  if (!value) return;
  return `${property}: ${value}${unit};`;
};

export const getStatus = (status) => {
  switch (status) {
    case true:
      return ['#41c787', 'True'];
    case false:
      return ['#ff4d4d', 'False'];
    case 'pending':
      return ['#f89c1c', 'Processing'];
    default:
      return ['#ff4d4d', 'False'];
  }
};

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
}

const midInput = (number) =>
  `^(0|([1-9]+\\d*))?(${escapeRegExp('.')}\\d{0,${number}})?$`;

export const numeric = (s, n = 5) => {
  return new RegExp(midInput(n)).test(s);
};

export const latin = (s) => /^[a-zA-Z0-9-_]*$/.test(s);

export const getFieldProps = (formik, name) => {
  const onChangeHandler = (n) => {
    switch (n) {
      case 'autoWithdraw.fixedAmount':
      case 'amount':
        return (ev) => {
          const { value } = ev.target;
          if (numeric(value)) {
            formik.setFieldValue(n, value);
          }
        };
      case 'percent':
      case 'showPercent':
        return (ev) => {
          const { value } = ev.target;
          if (numeric(value, 2)) {
            formik.setFieldValue(n, value);
          }
        };
      case 'userName':
        return (ev) => {
          const { value } = ev.target;
          if (latin(value)) {
            formik.setFieldValue(n, value);
          }
        };
      default:
        return formik.handleChange;
    }
  };

  return {
    touched: get(formik, `touched.${name}`, false),
    error: get(formik, `errors.${name}`, ''),
    ...formik.getFieldProps(name),
    onChange: onChangeHandler(name)
  };
};

export const getByValue = (arr, value) => {
  const result = arr.filter((item) => {
    return item.value === value;
  });
  return result.length > 0 ? result[0] : null;
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};
