import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { useWallet } from 'contexts/wallet/wallet.context';
import { Input } from 'components/common/form/input';
import Dropdown from 'components/common/form/dropdown/dropdown';

import { bigIntToFloat } from 'utils/helpers/converters';
import { heightCollapse } from 'utils/motion/height-collapse';
import { getFieldProps } from 'utils';

import { FormRow, FormField, LabelError } from 'assets/styles/form.style';
import DataModalToggler from '../../data-modal-toggler';

const FormManual = ({
  activeCoin,
  userBalance = 0,
  optionsCoins,
  state,
  mode,
  data,
  settings,
  children,
  handleClose
}) => {
  const { t } = useTranslation();
  const { wallets } = useWallet();
  const [coinId, setCoinId] = state;

  const initialValues = {
    coinId: activeCoin !== null ? activeCoin?.id : '',
    amount: '',
    walletId: ''
  };

  const optionsWallets = wallets
    .filter((item) => item.active && item.coinId === coinId)
    .map((item) => ({
      label: item.name,
      value: item.id
    }));

  yup.addMethod(yup.number, 'lessMoreThan', function () {
    return this.test('lessMoreThan', function (value) {
      const { path, createError } = this;

      if (userBalance < value) {
        return createError({
          path,
          message: t('walletsBlock.walletFields.balanceLessValue')
        });
      }

      if (value < bigIntToFloat(settings?.withdraw[0]?.minAmount)) {
        return createError({
          path,
          message: t('walletsBlock.walletFields.amountLessMin')
        });
      }

      if (value > bigIntToFloat(settings?.withdraw[0]?.maxAmount)) {
        return createError({
          path,
          message: t('walletsBlock.walletFields.amountMoreMax')
        });
      }
      return value;
    });
  });

  return (
    <Formik
      validateOnBlur
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        coinId: yup
          .string()
          .required(t('walletsBlock.walletFields.coinRequired')),
        walletId: yup
          .string()
          .required(t('withdrawalBlock.withdrawalFields.walletRequired')),
        amount: yup
          .number()
          .required(t('withdrawalBlock.withdrawalFields.amountRequired'))
          .lessMoreThan()
      })}
    >
      {(formik) => {
        setCoinId(formik.values.coinId);
        return (
          <DataModalToggler
            name="manual"
            data={data}
            mode={mode}
            handleClose={handleClose}
          >
            <FormRow columns={2}>
              <FormField>
                <Dropdown
                  name="coinId"
                  options={optionsCoins}
                  {...getFieldProps(formik, 'coinId')}
                />
              </FormField>
              <FormField>
                <Input
                  type="text"
                  name="amount"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  inputMode="decimal"
                  placeholder={t('withdrawalBlock.modal.placeholder')}
                  {...getFieldProps(formik, 'amount')}
                  required
                />
              </FormField>
              <AnimatePresence>
                {Boolean(formik.errors.amount) && formik.touched.amount && (
                  <FormField className="fullWidth">
                    <motion.div
                      initial="from"
                      animate="to"
                      exit="from"
                      variants={heightCollapse()}
                    >
                      <LabelError>{formik.errors.amount}</LabelError>
                    </motion.div>
                  </FormField>
                )}
              </AnimatePresence>
            </FormRow>
            <FormRow>
              <FormField>
                <Dropdown
                  name="walletId"
                  label={t('withdrawalBlock.modal.label')}
                  options={optionsWallets}
                  {...getFieldProps(formik, 'walletId')}
                />
                <AnimatePresence>
                  {Boolean(formik.errors.walletId) && formik.touched.walletId && (
                    <motion.div
                      initial="from"
                      animate="to"
                      exit="from"
                      variants={heightCollapse()}
                    >
                      <LabelError>{formik.errors.walletId}</LabelError>
                    </motion.div>
                  )}
                </AnimatePresence>
              </FormField>
            </FormRow>
            {children}
          </DataModalToggler>
        );
      }}
    </Formik>
  );
};

export default FormManual;
