import { gql } from '@apollo/client';

export const ACCOUNT_UPDATE_TWOFA = gql`
  mutation ($input: UserTwoFAUpdateInput!) {
    updateTwoFA(input: $input) {
      active
      secret
      recoveryCodes
      lastActivatedAt
    }
  }
`;

export const ACCOUNT_UPDATE_PASSWORD = gql`
  mutation ($input: UserPasswordUpdateInput!) {
    updatePassword(input: $input)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation ($input: UpdateUserProfileInput) {
    updateProfile(input: $input) {
      token
    }
  }
`;
