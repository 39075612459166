import styled from 'styled-components';
import css from '@styled-system/css';
import { variant } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

import { Button } from 'components/common/button/button';

export const FormField = styled.div`
  position: relative;
`;

const cssFields = (props) => ({
  display: 'block',
  width: '100%',
  p: '17px 20px',
  appearance: 'none',
  fontFamily: 'body',
  fontSize: 'base',
  lineHeight: 'body',
  border: '1px solid transparent',
  borderRadius: 'base',
  spellCheck: false,
  boxShadow: 'none',
  backgroundColor: props.error ? 'input.bgError' : 'input.bg',
  color: props.error
    ? 'input.textError'
    : props.readonly
    ? 'primary.regular'
    : 'input.text',
  pointerEvents: props.readonly ? 'none' : 'auto',

  '&::autofill': {
    backgroundColor: 'input.bg'
  },

  '&:focus': {
    outline: 'none'
  },

  '&::placeholder': {
    color: props.error ? 'input.textError' : 'input.placeholder'
  },

  '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },

  '&.disabled': {
    cursor: 'not-allowed',
    opacity: 0.6
  }
});

export const Input = styled.input(
  (props) => css(cssFields(props)),
  variant({
    variants: {
      small: {
        p: '10px',
        fontSize: 'xs'
      },
      outlined: {
        color: 'primary.regular',
        bg: 'transparent',
        p: '17px 20px',
        border: '1px solid',
        borderColor: 'input.border'
      },
      withButton: {
        p: '17px 120px 17px 20px'
      }
    }
  })
);

export const LabelEye = styled.label`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  color: ${themeGet('colors.input.placeholder')};
  transition: color 0.25s ease;
`;
