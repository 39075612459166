import { navigate } from 'gatsby';

import Logo from 'layouts/logo/logo';
import LogoImage from 'assets/images/logo.svg';
import MenuIcon from 'components/icons/MenuIcon';
import { useUI } from 'contexts/ui.context';
import { useUser } from 'contexts/user.context';
import { useWindowSize } from 'utils/hooks/use-window-size';
import MainMenu from 'layouts/main-menu/main-menu';
import { HeaderWrap, HeaderInner, ActionButton } from './header.style';
import Actions from './actions/actions';

const Header = () => {
  const { clearData, isAuthenticated } = useUser();
  const { openSidebar } = useUI();
  const { isMobile } = useWindowSize();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    clearData();
    navigate('/');
  };

  return (
    <HeaderWrap>
      <HeaderInner maxWidth={[1320]}>
        <Logo imageUrl={LogoImage} />

        {isMobile ? (
          <ActionButton onClick={openSidebar}>
            <MenuIcon />
          </ActionButton>
        ) : (
          <>
            <MainMenu isAuthenticated={isAuthenticated} />
            <Actions
              onLogout={handleLogout}
              isAuthenticated={isAuthenticated}
            />
          </>
        )}
      </HeaderInner>
    </HeaderWrap>
  );
};

export default Header;
