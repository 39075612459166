export const columnsStatistics = [
  {
    title: 'dailyStatisticBlock.date',
    dataIndex: 'dateTime'
  },
  {
    title: 'dailyStatisticBlock.reward',
    dataIndex: 'reward'
  },
  {
    title: 'dailyStatisticBlock.hashrate',
    dataIndex: 'hashRate'
  },
  {
    title: 'dailyStatisticBlock.luck',
    dataIndex: 'luck'
  },
  {
    title: 'dailyStatisticBlock.block',
    dataIndex: 'blockCount'
  }
];

export const columnsTransactions = [
  {
    title: 'transactionsBlock.table.date',
    dataIndex: 'createdAt'
  },
  {
    title: 'transactionsBlock.table.coin',
    dataIndex: 'coinId'
  },
  {
    title: 'transactionsBlock.table.amount',
    dataIndex: 'amount'
  },
  {
    title: 'transactionsBlock.table.walletName',
    dataIndex: 'networkInfo.toAddress'
  },
  {
    title: 'transactionsBlock.table.status',
    dataIndex: 'status'
  }
];

export const columnsMinerallToday = [
  {
    title: 'minerallTodayBlock.dateI18n',
    dataIndex: 'time'
  },
  {
    title: 'minerallTodayBlock.lastBlockI18n',
    dataIndex: 'number'
  },
  {
    title: 'minerallTodayBlock.lastRewardI18n',
    dataIndex: 'rewardAmount'
  },
  {
    title: 'minerallTodayBlock.luckI18n',
    dataIndex: 'luck'
  },
  {
    title: 'minerallTodayBlock.difficultyI18n',
    dataIndex: 'difficulty'
  }
];

export const columnsWallets = [
  {
    title: 'walletsBlock.table.coin',
    dataIndex: 'coinId'
  },
  {
    title: 'walletsBlock.table.name',
    dataIndex: 'name'
  },
  {
    title: 'walletsBlock.table.address',
    dataIndex: 'address'
  },
  {
    title: 'walletsBlock.table.time',
    dataIndex: 'createdAt'
  },
  {
    title: 'walletsBlock.table.status',
    dataIndex: 'active'
  }
];

export const columnsWorkers = [
  {
    title: 'workers.id',
    dataIndex: 'workerId'
  },
  {
    title: 'workers.status',
    dataIndex: 'active'
  },
  {
    title: 'workers.lastShare',
    dataIndex: 'lastShare'
  },
  {
    title: 'workers.currentHashrate',
    dataIndex: 'hashRate'
  }
];

export const columnsReferralsStatistics = [
  {
    title: 'pageReferrals.table.name',
    dataIndex: 'userName'
  },
  {
    title: 'pageReferrals.table.coin',
    dataIndex: '',
    sortable: false
  },
  {
    title: 'pageReferrals.table.reward',
    dataIndex: 'reward'
  },
  {
    title: 'pageReferrals.table.hashrate',
    dataIndex: 'hashRate'
  }
];

export const columnsReferralsActivity = [
  {
    title: 'pageReferrals.table.name',
    dataIndex: 'userName'
  },
  {
    title: 'pageReferrals.table.coin',
    dataIndex: '',
    sortable: false
  },
  {
    title: 'pageReferrals.table.activeWorkers',
    dataIndex: 'activeWorkers'
  },
  {
    title: 'pageReferrals.table.inactiveWorkers',
    dataIndex: 'inactiveWorkers'
  },
  {
    title: 'pageReferrals.table.share',
    dataIndex: 'lastShare'
  },
  {
    title: 'pageReferrals.table.status',
    dataIndex: 'active'
  }
];
