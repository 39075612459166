import fetch from 'isomorphic-fetch';

import {
  ApolloLink,
  ApolloClient,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('access_token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const publicLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_API_ENDPOINT,
  fetch
});

const privateLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_API_ENDPOINT_PRIVATE,
  fetch
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'private',
    authLink.concat(privateLink),
    publicLink
  )
});

export default client;
