import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useMutation } from '@apollo/client';

import { ACCOUNT_UPDATE_PASSWORD } from 'graphql/account.mutation';
import { useUI } from 'contexts/ui.context';
import { passwordRe } from 'utils/constant';
import { useNotify } from 'contexts/notification.context';
import { heightCollapse } from 'utils/motion/height-collapse';
import { PasswordInput } from 'components/password-input/password-input';
import { Button } from 'components/common/button/button';

import {
  FormField,
  FormFieldsWrapper,
  FormRow,
  Heading,
  LabelError,
  LabelInput,
  LayoutWrapper,
  Wrapper
} from 'assets/styles/form.style';

export default function ResetPassModal() {
  const { t } = useTranslation();
  const { closeModal } = useUI();
  const { setMessage, setMessageError } = useNotify();
  const [loading, setLoading] = useState(false);
  const [updatePassword] = useMutation(ACCOUNT_UPDATE_PASSWORD);

  const submitCallback = async (
    values,
    { touched, validateForm, setTouched, setSubmitting, resetForm }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        setLoading(true);
        const result = await updatePassword({
          variables: {
            input: values
          },
          context: { clientName: 'private' }
        });
        setLoading(false);

        if (result?.data?.updatePassword) {
          setMessage('notifications.auth.reset', {
            status: 'success'
          });
          closeModal();
        }
      } catch (err) {
        setLoading(false);
        setMessageError(err, true);
        resetForm();
      }
      setSubmitting(false);
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <LayoutWrapper>
      <Wrapper>
        <Heading>{t('authForm.changePassLabel')}</Heading>
        <Formik
          validateOnBlur
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={yup.object().shape({
            oldPassword: yup
              .string()
              .required(t('authForm.registerFields.passwordRequired'))
              .min(8, t('authForm.registerFields.passwordMin'))
              .max(32, t('authForm.registerFields.passwordMax'))
              .matches(
                passwordRe,
                t('authForm.registerFields.passwordMatches')
              ),
            newPassword: yup
              .string()
              .required(t('authForm.registerFields.passwordRequired'))
              .min(8, t('authForm.registerFields.passwordMin'))
              .max(32, t('authForm.registerFields.passwordMax'))
              .matches(
                passwordRe,
                t('authForm.registerFields.passwordMatches')
              ),
            confirmPassword: yup.string().when('newPassword', {
              is: (value) => value && value.length > 0,
              then: yup
                .string()
                .required(t('authForm.registerFields.passwordRequired'))
                .oneOf(
                  [yup.ref('newPassword'), null],
                  t('authForm.registerFields.passwordMatch')
                )
            })
          })}
          onSubmit={submitCallback}
        >
          {({ isValid, values, handleChange, handleBlur, touched, errors }) => (
            <Form noValidate>
              <FormFieldsWrapper>
                <FormRow>
                  <FormField>
                    <LabelInput htmlFor="oldPassword">
                      {t('authForm.passwordOldLabel')}
                    </LabelInput>
                    <PasswordInput
                      id="oldPassword"
                      name="oldPassword"
                      placeholder={t('authForm.passwordOldPlaceholder')}
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.oldPassword) && touched.oldPassword}
                      required
                    />
                    <AnimatePresence>
                      {Boolean(errors.oldPassword) && touched.oldPassword && (
                        <motion.div
                          initial="from"
                          animate="to"
                          exit="from"
                          variants={heightCollapse()}
                        >
                          <LabelError>{errors.oldPassword}</LabelError>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <LabelInput htmlFor="newPassword">
                      {t('authForm.passwordNewLabel')}
                    </LabelInput>
                    <PasswordInput
                      id="newPassword"
                      name="newPassword"
                      placeholder={t('authForm.passwordNewPlaceholder')}
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.newPassword) && touched.newPassword}
                      required
                    />
                    <AnimatePresence>
                      {Boolean(errors.newPassword) && touched.newPassword && (
                        <motion.div
                          initial="from"
                          animate="to"
                          exit="from"
                          variants={heightCollapse()}
                        >
                          <LabelError>{errors.newPassword}</LabelError>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <LabelInput htmlFor="confirmPassword">
                      {t('authForm.confirmNewPasswordLabel')}
                    </LabelInput>
                    <PasswordInput
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder={t('authForm.confirmNewPasswordPlaceholder')}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        Boolean(errors.confirmPassword) &&
                        touched.confirmPassword
                      }
                      required
                    />
                    <AnimatePresence>
                      {Boolean(errors.confirmPassword) &&
                        touched.confirmPassword && (
                          <motion.div
                            initial="from"
                            animate="to"
                            exit="from"
                            variants={heightCollapse()}
                          >
                            <LabelError>{errors.confirmPassword}</LabelError>
                          </motion.div>
                        )}
                    </AnimatePresence>
                  </FormField>
                </FormRow>
                <FormRow>
                  <Button
                    disabled={!isValid}
                    loading={loading}
                    type="submit"
                    fullwidth
                  >
                    {t('authForm.confirmPasswordBtn')}
                  </Button>
                </FormRow>
              </FormFieldsWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </LayoutWrapper>
  );
}
