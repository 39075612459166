import styled from 'styled-components';
import { motion } from 'framer-motion';
import { themeGet } from '@styled-system/theme-get';

export const StyledDrawer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 50;
  overflow: auto;
  display: none;

  @media (max-width: 992px) {
    display: flex;
  }
`;

export const SidekickBody = styled(motion.div)`
  position: relative;
  z-index: 1;
  pointer-events: all;
  height: 100%;
  width: 100%;
  background-color: ${themeGet('colors.body.bg')};
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: ${themeGet('shadows.section')};
`;
