import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledButton = styled.button((props) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: '20px',
    py: '16px',
    cursor: props.active ? 'default' : 'pointer',
    color: props.active ? '#fff' : 'text.default',
    bg: props.active ? 'primary.regular' : 'primary.light',
    boxShadow: props.active ? 'button' : '',
    transition: 'all 0.3s ease',
    borderRadius: 'small',
    fontSize: 'base',
    width: '100%',
    textTransform: 'none',
    whiteSpace: 'nowrap',

    '> svg': {
      marginRight: '10px'
    },

    '&:focus': {
      outline: 'none'
    },

    '&:hover': {
      bg: props.active ? '' : 'button.hover',
      color: props.active ? '' : '#fff'
    }
  })
);

export const Button = ({ children, active, ...rest }) => (
  <StyledButton active={active} {...rest}>
    {children}
  </StyledButton>
);
