import { memo } from 'react';

import WithdrawalModal from './modals/withdrawal-modal/withdrawal-modal';
import WalletModal from './modals/wallet-modal';
import WalletConfirmModal from './modals/wallet-confirm-modal';
import WithdrawalConfirmModal from './modals/withdrawal-confirm-modal';

const ModalRegistrar = () => (
  <>
    <WithdrawalModal />
    <WalletModal />
    <WalletConfirmModal />
    <WithdrawalConfirmModal />
  </>
);

export default memo(ModalRegistrar);
