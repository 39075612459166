import { useTranslation } from 'react-i18next';

import { useCoins } from 'contexts/coins.context';
import {
  bigIntToFloat,
  handleParseNumber,
  normalizeDate,
  toFixed,
  truncateName
} from 'utils/helpers/converters';

const useNormalizeCell = (item, dataIndex, key = true) => {
  const { t } = useTranslation();
  const { coins } = useCoins();
  let className = '';
  let value = item[dataIndex];

  if (dataIndex.indexOf('.') > 0) {
    const index = dataIndex.split('.');
    value = item[index[0]][index[1]];
  }

  let cellValue = key ? value : item;

  switch (dataIndex) {
    case 'createdAt':
    case 'updatedAt':
    case 'lastShare':
    case 'dateTime':
      cellValue = normalizeDate(cellValue);
      break;
    case 'coinId':
      className = 'table-weight-bold';
      cellValue = coins.find((c) => c?.id === cellValue)?.tag.toUpperCase();
      break;
    case 'status':
      if (item[dataIndex] === '0') {
        className = 'table-alert-warning';
        cellValue = t('gridTable.statuses.created');
      }
      if (item[dataIndex] === '1') {
        cellValue = t('gridTable.statuses.approved');
      }
      if (item[dataIndex] === '2') {
        cellValue = t('gridTable.statuses.processing');
      }
      if (item[dataIndex] === '3') {
        cellValue = t('gridTable.statuses.success');
      }
      if (item[dataIndex] === '4') {
        cellValue = t('gridTable.statuses.decline');
      }
      if (item[dataIndex] === '5' || item[dataIndex] === '6') {
        className = 'table-alert-error';
        cellValue = t('gridTable.statuses.error');
      }
      break;
    case 'active':
      className = item[dataIndex]
        ? 'table-weight-bold'
        : 'table-weight-bold table-cell-inactive';

      cellValue = item[dataIndex] ? t('workers.active') : t('workers.inactive');
      break;
    case 'amount':
    case 'reward':
      cellValue = toFixed(bigIntToFloat(cellValue));
      break;
    case 'networkInfo.toAddress':
      cellValue = truncateName(cellValue, 8);
      break;
    case 'expectedReward':
    case 'networkExpectedReward':
      cellValue = toFixed(cellValue);
      break;
    case 'hashRate':
      const parseNumber = handleParseNumber(cellValue);
      cellValue = `${parseNumber?.val} ${parseNumber?.metric}`;
      break;
    case 'luck':
      cellValue = `${Math.floor(cellValue * 100)} %`;
      break;
    default:
      break;
  }
  return { className, cellValue };
};

export default useNormalizeCell;
