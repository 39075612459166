import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButton = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
  &:first-child {
    margin-right: 10px;
  }
`;

export const AuthBox = styled.div`
  display: flex;
  align-items: center;
`;

export const AuthButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px 17px;
  color: ${themeGet('colors.text.default')};
  background-color: ${themeGet('colors.primary.light')};
  border-radius: ${themeGet('radii.base')};
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-right: 11px;
  }
`;
