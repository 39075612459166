import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';
import { AuthButton } from './actions.style';

const AuthMenu = ({ onLogout, isAuthenticated, icon }) => {
  const { t } = useTranslation();

  const onLogin = () => {
    navigate(AUTH_LOGIN_PAGE);
  };

  return !isAuthenticated ? (
    <AuthButton onClick={onLogin}>
      {icon} {t('header.login')}
    </AuthButton>
  ) : (
    <AuthButton onClick={onLogout}>
      {icon} {t('header.logout')}
    </AuthButton>
  );
};
export default AuthMenu;
