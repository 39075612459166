const ETCIconNoShadow = ({
  width = 13,
  height = 25,
  color = 'currentColor'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.1429L6.19034 7.11895L12.3301 11.1679L6.16335 0L0 11.1429ZM0.236531 12.3051L6.19747 8.37694L12.0808 12.2759L6.20085 16.2082L0.236531 12.3051ZM0 13.4297C2.17252 14.8543 4.4395 16.3456 6.19034 17.4994L12.3301 13.4297C10.1069 17.5078 8.25491 20.9027 6.19034 24.66L4.92394 22.3645C3.22322 19.2819 1.46939 16.103 0 13.4297Z"
        fill={color}
      />
    </svg>
  );
};

export default ETCIconNoShadow;
