import { gql } from '@apollo/client';

export const CREATE_WITHDRAW = gql`
  mutation ($input: TransactionWithdraw!) {
    createWithdraw(input: $input)
  }
`;

export const CREATE_AUTO_WITHDRAW = gql`
  mutation ($walletId: ObjectID!, $input: WalletAutoWithdrawInput!) {
    createAutoWithdraw(walletId: $walletId, input: $input) {
      id
      coinId
      active
      address
      name
      networkType
      createdAt
      autoWithdraw {
        type
        fixedAmount
      }
    }
  }
`;

export const UPDATE_AUTO_WITHDRAW = gql`
  mutation ($walletId: ObjectID!, $input: WalletAutoWithdrawInput!) {
    updateAutoWithdraw(walletId: $walletId, input: $input) {
      id
      coinId
      active
      address
      name
      networkType
      createdAt
      autoWithdraw {
        type
        fixedAmount
      }
    }
  }
`;

export const DELETE_AUTO_WITHDRAW = gql`
  mutation ($walletId: ObjectID!) {
    deleteAutoWithdraw(walletId: $walletId)
  }
`;
