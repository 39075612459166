import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { useNotify } from 'contexts/notification.context';
import CloseIcon from 'components/icons/CloseIcon';
import { Text } from 'components/common/text/text';

import { StyledContainer, StyledCloseButton } from './notification-popup.style';

const NotificationPopup = () => {
  const { t } = useTranslation();
  const { showFlash, message, hideNotify, options } = useNotify();
  const { status, closeOnClick } = options;

  return (
    <StyledContainer className={`is-${status}`}>
      <AnimatePresence initial={false}>
        {showFlash && (
          <motion.div
            className="styled-wrapper"
            positionTransition
            initial={{ opacity: 0, y: '-60px', scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: '-60px', transition: { duration: 0.2 } }}
          >
            <Text size="sm" color="text.light">
              {t(message)}
            </Text>
            {closeOnClick && (
              <StyledCloseButton onClick={hideNotify}>
                <CloseIcon />
              </StyledCloseButton>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </StyledContainer>
  );
};

export default NotificationPopup;
