import { useTranslation } from 'react-i18next';

import { cn, en, Fromnow, ua } from 'utils/react-fromnow/react-fromnow';
import useDateFormat from 'utils/hooks/use-date-format';

import {
  bigIntToFloat,
  dateFullTime,
  getPrice,
  handleParseNumber,
  toFixed
} from 'utils/helpers/converters';

import {
  StyledLabel,
  StyledLink,
  StyledSubValue,
  StyledSubValueInfo,
  StyledValueInfo
} from './table-block.style';

export const useNormalizeCell = ({
  item,
  dataIndex,
  activeCoin,
  isHead = false
}) => {
  const { i18n } = useTranslation();
  let cellValue = item[dataIndex];
  const { dateFullMonth } = useDateFormat();

  const lang = () => {
    switch (i18n.language) {
      case 'en':
        return en();
      case 'ua':
        return ua();
      case 'cn':
        return cn();
      default:
        return ua();
    }
  };

  switch (dataIndex) {
    case 'dateTime':
    case 'time':
      cellValue = isHead ? dateFullMonth(cellValue) : dateFullTime(cellValue);
      break;
    case 'hashRate':
    case 'difficulty':
      const parseNumber = handleParseNumber(Number(cellValue));
      cellValue = `${parseNumber.val} ${parseNumber.metric}`;
      break;
    case 'luck':
      cellValue = `${Math.floor(cellValue * 100)} %`;
      break;
    case 'number':
      const attrs = {};
      if (!isHead) {
        const network = activeCoin?.url;
        const url =
          item.type === 'simple'
            ? network?.explorerBlockSimple.replace('{number}', cellValue)
            : network?.explorerBlockUncle.replace(
                '{hash}',
                network?.explorer.indexOf('etherscan') > 0
                  ? cellValue
                  : item?.hash
              );
        attrs.as = 'a';
        attrs.href = url;
        attrs.target = '_blank';
        attrs.rel = 'nofollow noopener';
      }

      cellValue = (
        <>
          <StyledLink {...attrs}>
            {cellValue}{' '}
            {isHead && <Fromnow lang={lang()} tag="span" date={item.time} />}
          </StyledLink>
          {item.type === 'uncle' && <StyledLabel>uncle</StyledLabel>}
        </>
      );
      break;
    case 'reward':
    case 'rewardAmount':
      cellValue = (
        <>
          <StyledValueInfo>$</StyledValueInfo>{' '}
          {getPrice(
            bigIntToFloat(cellValue, activeCoin?.decimals),
            item?.prices
          )}{' '}
          /{' '}
          <StyledSubValue>
            {toFixed(bigIntToFloat(cellValue, activeCoin?.decimals))}
          </StyledSubValue>
          <StyledSubValueInfo>
            {' '}
            {activeCoin?.tag.toUpperCase()}
          </StyledSubValueInfo>
        </>
      );
      break;
    default:
      break;
  }
  return { cellValue };
};
