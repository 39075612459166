import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTooltip = styled.div`
  position: relative;
  display: inline-flex;
`;

export const StyledTooltipToggler = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledTooltipMessage = styled.div`
  position: absolute;
  z-index: 20;
  padding: 14px 16px;
  background-color: ${themeGet('colors.body.bg')};
  border-radius: ${themeGet('radii.base')};
  text-decoration: none;
  text-transform: none;
  font-weight: ${themeGet('fontWeights.regular')};
  width: 194px;
  color: ${themeGet('colors.tooltip.color')};
  text-align: center;
  font-size: ${themeGet('fontSizes.xs')}px;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    content: '';
  }

  &.top {
    bottom: 100%;
    transform: translate(-50%);
    margin-bottom: 15px;
    filter: drop-shadow(${themeGet('shadows.tooltip')});

    &::after {
      border-top-color: ${themeGet('colors.body.bg')};
      border-bottom: none;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0;
    }
  }

  &.left {
    top: 50%;
    right: 100%;
    margin-right: 15px;
    filter: drop-shadow(${themeGet('shadows.tooltip')});

    &::after {
      border-left-color: ${themeGet('colors.body.bg')};
      border-right: none;
      top: 50%;
      margin-top: -3px;
      right: -6px;
    }
  }
`;
