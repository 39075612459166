import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { motion, AnimatePresence } from 'framer-motion';
import { useMutation } from '@apollo/client';

import { UPDATE_PROFILE, ACCOUNT_UPDATE_TWOFA } from 'graphql/account.mutation';
import { useUI } from 'contexts/ui.context';
import { heightCollapse } from 'utils/motion/height-collapse';
import { PasswordInput } from 'components/password-input/password-input';
import { Button } from 'components/common/button/button';

import {
  LayoutWrapper,
  Wrapper,
  Heading,
  FormFieldsWrapper,
  FormField,
  FormRow,
  LabelInput,
  LabelError
} from 'assets/styles/form.style';

export default function VerifyPassModal() {
  const { t } = useTranslation();
  const {
    verifyStatus,
    closeModal,
    showTwoFAModal,
    setActiveTwoFaToggler,
    setUpdateToken
  } = useUI();
  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE);
  const [updateTwoFa] = useMutation(ACCOUNT_UPDATE_TWOFA);

  const offTwoFaHandler = async (token) => {
    const result = await updateTwoFa({
      variables: {
        input: { token, active: false }
      },
      context: { clientName: 'private' }
    });
    if (!result?.data?.updateTwoFA?.active) {
      setActiveTwoFaToggler(false);
    }
  };

  const submitCallback = async (
    values,
    { touched, validateForm, setTouched, setSubmitting, resetForm }
  ) => {
    const errs = await validateForm();

    if (isEmpty(errs)) {
      try {
        const result = await updateProfile({
          variables: {
            input: values
          },
          context: { clientName: 'private' }
        });

        if (result?.data?.updateProfile?.token) {
          setSubmitting(false);
          setUpdateToken(result.data.updateProfile.token);
          if (verifyStatus) {
            showTwoFAModal();
          } else {
            offTwoFaHandler(result.data.updateProfile.token);
            closeModal();
          }
        } else {
          setSubmitting(false);
        }
      } catch (err) {
        resetForm();
      }
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <LayoutWrapper>
      <Wrapper>
        <Heading>{t('authForm.identifyHeadingLabel')}</Heading>
        <Formik
          validateOnBlur
          initialValues={{
            password: ''
          }}
          validationSchema={yup.object().shape({
            password: yup
              .string()
              .required(t('authForm.registerFields.passwordRequired'))
              .min(8, t('authForm.registerFields.passwordMin'))
              .max(32, t('authForm.registerFields.passwordMax'))
              .matches(
                /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@$&*#_-]).+/,
                t('authForm.registerFields.passwordMatches')
              )
          })}
          onSubmit={submitCallback}
        >
          {({ isValid, values, handleChange, handleBlur, touched, errors }) => (
            <Form noValidate>
              <FormFieldsWrapper>
                <FormRow>
                  <FormField>
                    <LabelInput htmlFor="password">
                      {t('authForm.identifyLabel')}
                    </LabelInput>
                    <PasswordInput
                      id="password"
                      name="password"
                      placeholder={t('authForm.identifyPlaceholder')}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.password) && touched.password}
                      required
                    />
                    <AnimatePresence>
                      {Boolean(errors.password) && touched.password && (
                        <motion.div
                          initial="from"
                          animate="to"
                          exit="from"
                          variants={heightCollapse()}
                        >
                          <LabelError>{errors.password}</LabelError>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </FormField>
                </FormRow>
                <FormRow>
                  <Button
                    disabled={!isValid}
                    loading={loading}
                    type="submit"
                    fullwidth
                  >
                    {t('authForm.identifyConfirm')}
                  </Button>
                </FormRow>
              </FormFieldsWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </LayoutWrapper>
  );
}
