import { useEffect } from 'react';
import { navigate } from 'gatsby';

import { tokenRefreshAPI } from 'utils/api';
import ManagedModal from 'components/common/modal/managed-modal';
import MobileNavigation from 'layouts/mobile-navigation/mobile-navigation';
import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';
import { checkToken } from 'utils/get-token';
import { LayoutWrapper } from './app-layout.style';
import Footer from '../footer/footer';
import Header from '../header/header';
import Banner from '../announcement-banner/banner';

const MainLayout = ({ children, location }) => {
  useEffect(() => {
    async function refreshToken() {
      const token = checkToken();
      if (token === 'refresh') {
        const data = await tokenRefreshAPI();
        if (data?.token) {
          localStorage.setItem('access_token', data?.token);
        }
      }
      if (token === 'expired') {
        navigate(AUTH_LOGIN_PAGE);
      }
    }
    refreshToken();
  }, []);

  return (
    <>
      <LayoutWrapper>
        <Banner />
        <Header />
        {children}
        {location?.pathname.indexOf('/auth') < 0 && <Footer />}
        <MobileNavigation />
      </LayoutWrapper>
      <ManagedModal />
    </>
  );
};

export default MainLayout;
