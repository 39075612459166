import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/common/toggler/toggler-button';
import { useWallet } from 'contexts/wallet/wallet.context';
import { ButtonWrapper } from './withdrawal-toggler.style';

import { useIsAutoWithdraw } from '../hooks';

const options = [
  {
    name: 'withdrawalBlock.modal.toggler.withdrawal',
    value: 'manual'
  },
  {
    name: 'withdrawalBlock.modal.toggler.auto',
    value: 'auto'
  }
];

const Toggler = ({ editMode, state, coinId }) => {
  const { t } = useTranslation();
  const { wallets } = useWallet();
  const isAuto = useIsAutoWithdraw(wallets, coinId);
  const [value, setValue] = state;

  useEffect(() => {
    return () => {
      setValue('manual');
    };
  }, []);

  return (
    <ButtonWrapper>
      {options
        .filter((item) => {
          return editMode
            ? item.value === 'auto'
            : (isAuto[coinId] !== '' && item.value === 'manual') ||
                isAuto[coinId] === '';
        })
        .map((item) => {
          return (
            <Button
              key={item.name}
              onClick={() => setValue(item.value)}
              active={item.value === value}
            >
              {t(item.name)}
            </Button>
          );
        })}
    </ButtonWrapper>
  );
};

export default Toggler;
