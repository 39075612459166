import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';

import { Inc4Logo, MinerallIcon, TelegramIcon } from 'components/icons/footer';

import {
  Block,
  ChatsContainer,
  Container,
  CopyrightText,
  FooterBottom,
  FooterTop,
  FooterWrapper,
  Heading,
  SocialsContainer
} from './footer.style';

import { chats, press, products, socials } from './links';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <FooterTop>
        <MinerallIcon />

        <Block>
          <Heading>{t('footer.products.title')}</Heading>
          {products.map(({ title, url }) => (
            <a
              key={title}
              href={url}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {t(`footer.products.${title}`)}
            </a>
          ))}
          <Link to="../staking">{t('footer.products.staking')}</Link>
        </Block>

        <Block>
          <Heading>{t('footer.support.title')}</Heading>
          <a
            href="mailto:support@minerall.io"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {t('footer.support.contactUs')}
          </a>
          <Link to="../faq">{t('footer.support.faq')}</Link>
        </Block>

        <Block>
          <Heading>{t('footer.press.title')}</Heading>

          {press.map(({ item, url }) => (
            <Link key={url} to={url}>
              {t(`footer.press.${item}`)}
            </Link>
          ))}

          <a
            href="https://news.minerall.io/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            {t('footer.press.blog')}
          </a>
        </Block>
      </FooterTop>

      <FooterBottom>
        <CopyrightText size="xs">
          {t('footer.rights')}
          <br />
          {t('footer.rightsMinerall')}
          <br />
          {t('footer.rightsReserved')}
        </CopyrightText>

        <Block>
          <Heading>{t('footer.socials')}</Heading>

          <SocialsContainer>
            {socials.map(({ url, icon }) => (
              <a
                key={url}
                href={url}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {icon}
              </a>
            ))}
          </SocialsContainer>
        </Block>

        <Block>
          <Heading>{t('footer.chatSupport')}</Heading>

          <ChatsContainer>
            {chats.map(({ url, title }) => (
              <Container
                key={url}
                href={url}
                target="_blank"
                rel="nofollow noopener"
              >
                <TelegramIcon />
                <span>{title}</span>
              </Container>
            ))}
          </ChatsContainer>
        </Block>

        <Block>
          <a
            href="https://inc4.net/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Inc4Logo />
          </a>
        </Block>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
