import { useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { useWallet } from 'contexts/wallet/wallet.context';
import { FixedAmountField } from 'components/wallets/wallet-modals/modals/custom-fields';
import Dropdown from 'components/common/form/dropdown/dropdown';
import ToggleButton from 'components/wallets/components/toggle-button/toggle-button-modify';
import DataModalToggler from 'components/wallets/wallet-modals/data-modal-toggler';

import { heightCollapse } from 'utils/motion/height-collapse';
import { getFieldProps } from 'utils';

import { FormRow, FormField, LabelError } from 'assets/styles/form.style';

const FormAuto = ({
  optionsCoins,
  state,
  mode,
  data,
  settings,
  handleClose,
  children
}) => {
  const { t } = useTranslation();
  const { wallets } = useWallet();
  const [coinId, setCoinId] = state;
  const [isEditMode] = mode;

  const initialValues = {
    coinId: '',
    id: '',
    autoWithdraw: {
      type: 'day',
      fixedAmount: '0'
    }
  };

  const optionsWallets = useMemo(
    () =>
      wallets
        .filter((item) => item.active && item.coinId === coinId)
        .map((item) => ({
          label: item.name,
          value: item.id
        })),
    [coinId]
  );

  const modifyOptionsCoins = useMemo(
    () =>
      optionsCoins.filter((item) => {
        const isAutoWithdraw = wallets.filter(
          (w) => w.autoWithdraw !== null && w.coinId === item.value
        );
        return isAutoWithdraw.length === 0;
      }),
    [optionsCoins]
  );

  return (
    <Formik
      validateOnBlur
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        coinId: yup
          .string()
          .required(t('walletsBlock.walletFields.coinRequired')),
        id: yup
          .string()
          .required(t('withdrawalBlock.withdrawalFields.walletRequired')),
        autoWithdraw: yup.object().shape({
          type: yup.mixed().oneOf(['day', 'hour']),
          fixedAmount: yup.number()
        })
      })}
    >
      {(formik) => {
        setCoinId(formik.values.coinId);
        return (
          <DataModalToggler
            name="auto"
            data={data}
            mode={mode}
            handleClose={handleClose}
          >
            <FormRow>
              <FormField>
                <Dropdown
                  name="coinId"
                  disabled={isEditMode}
                  options={isEditMode ? optionsCoins : modifyOptionsCoins}
                  {...getFieldProps(formik, 'coinId')}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Dropdown
                  name="id"
                  disabled={isEditMode}
                  label={t('withdrawalBlock.modal.label')}
                  options={optionsWallets}
                  {...getFieldProps(formik, 'id')}
                />
                <AnimatePresence>
                  {Boolean(formik.errors.id) && formik.touched.id && (
                    <motion.div
                      initial="from"
                      animate="to"
                      exit="from"
                      variants={heightCollapse()}
                    >
                      <LabelError>{formik.errors.id}</LabelError>
                    </motion.div>
                  )}
                </AnimatePresence>
              </FormField>
            </FormRow>
            {children}
            <ToggleButton
              name="autoWithdraw.type"
              formik={formik}
              {...getFieldProps(formik, 'autoWithdraw.type')}
            >
              <FixedAmountField
                name="autoWithdraw.fixedAmount"
                editMode={isEditMode}
                settings={settings}
                {...getFieldProps(formik, 'autoWithdraw.fixedAmount')}
              />
            </ToggleButton>
          </DataModalToggler>
        );
      }}
    </Formik>
  );
};

export default FormAuto;
