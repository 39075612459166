import { ApolloProvider } from '@apollo/client';
import client from 'gatsby-plugin-apollo/client';
import MainLayout from 'layouts/app-layout/app-layout';
import { ThemeContextProvider } from 'contexts/theme.context';
import { ManagedUIContext } from 'contexts/ui.context';
import { UserProvider } from 'contexts/user.context';
import { WalletProvider } from 'contexts/wallet/wallet.context';
import { DataContextProvider } from 'contexts/data/data.context';
import { NotificationContextProvider } from 'contexts/notification.context';
import { CoinsProvider } from 'contexts/coins.context';

import { GlobalStyle } from 'assets/styles/global.style';
import './src/i18n';
import 'overlayscrollbars/css/OverlayScrollbars.min.css';
import 'assets/styles/reset.css';

export function wrapRootElement({ element }) {
  return (
    <ApolloProvider client={client}>
      <CoinsProvider>
        <ThemeContextProvider>
          <ManagedUIContext>
            <UserProvider>
              <NotificationContextProvider>
                <WalletProvider>
                  <DataContextProvider>{element}</DataContextProvider>
                </WalletProvider>
              </NotificationContextProvider>
            </UserProvider>
          </ManagedUIContext>
        </ThemeContextProvider>
      </CoinsProvider>
    </ApolloProvider>
  );
}

export function wrapPageElement({ element, props: { location } }) {
  return (
    <MainLayout location={location}>
      {element}
      <GlobalStyle />
    </MainLayout>
  );
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};
