import UpIcon from 'components/icons/UpIcon';
import { Button } from './scroll-button.style';

const ScrollToTopButton = ({ handleClick }) => {
  return (
    <Button type="button" onClick={handleClick}>
      <UpIcon />
    </Button>
  );
};

export default ScrollToTopButton;
