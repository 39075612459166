const BitcoinTalkIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM16.9919 10.1332C17.2271 8.56098 16.03 7.71582 14.3932 7.152L14.9242 5.02228L13.6278 4.6992L13.1109 6.7728C12.7701 6.68788 12.4201 6.60775 12.0722 6.52837L12.5929 4.44111L11.2972 4.11803L10.7659 6.24701C10.4838 6.18277 10.2069 6.11926 9.93809 6.05243L9.93957 6.04578L8.15175 5.59938L7.80689 6.984C7.80689 6.984 8.76874 7.20443 8.74843 7.21809C9.27348 7.34917 9.36837 7.69662 9.35249 7.97206L8.74769 10.3983C8.78388 10.4075 8.83077 10.4208 8.88246 10.4415C8.83926 10.4308 8.79311 10.419 8.74548 10.4075L7.89772 13.8063C7.83348 13.9658 7.67065 14.205 7.30363 14.1142C7.31655 14.133 6.36135 13.879 6.36135 13.879L5.71778 15.363L7.4048 15.7835C7.71865 15.8622 8.02621 15.9445 8.32898 16.022L7.79249 18.1761L9.08738 18.4992L9.61871 16.368C9.97243 16.464 10.3158 16.5526 10.6518 16.6361L10.1223 18.7573L11.4187 19.0804L11.9552 16.9303C14.1658 17.3487 15.8281 17.1799 16.5278 15.1806C17.0916 13.5707 16.4997 12.6421 15.3366 12.0366C16.1836 11.8412 16.8217 11.2841 16.9919 10.1332Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4309 10.1099C14.0654 11.5743 11.8094 10.8303 11.0775 10.6479L11.723 8.05957C12.4548 8.24197 14.8116 8.5824 14.4309 10.1099Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0299 14.2866C13.6293 15.8965 10.9188 15.0262 10.04 14.808L10.7519 11.9542C11.6306 12.1735 14.4486 12.6078 14.0299 14.2866Z"
        fill="white"
      />
    </svg>
  );
};

export default BitcoinTalkIcon;
