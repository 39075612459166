import { gql } from '@apollo/client';

export const GET_COINS = gql`
  query {
    coins(coinTags: ["etc"]) {
      id
      algorithm
      name
      tag
      icon
      decimals
      url {
        website
        explorer
        explorerBlockSimple
        explorerBlockUncle
        explorerNetwork {
          transaction
        }
      }
      mining {
        blockRewardType
        blockMinerAddress
        blockConfirmations
        pools {
          name
          region
          host
          port
        }
        miners
      }
      transaction {
        reward {
          blocked
          poolFee
        }
        withdraw {
          networkType
          blocked
          poolFee
          networkFee
          minAmount
          maxAmount
        }
      }
    }
  }
`;

export const GET_COINS_INFO = gql`
  query {
    coinsInfo {
      coinId
      averageBlockTime
      networkDifficulty
      networkHashRate
      poolUsers
      poolWorkers
      poolBlocksDay
      poolBlocksTotal
      poolRewardTotal
      reward
      hashRate
      luck
      prices
      lastBlock
      lastShare
    }
  }
`;

export const GET_COINS_BLOCKS_TOTAL = gql`
  query {
    coinsInfo {
      coinId
      poolBlocksTotal
    }
  }
`;
