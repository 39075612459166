import { useEffect, useRef } from 'react';
import Portal from '@reach/portal';
import { AnimatePresence, motion } from 'framer-motion';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';

import { fadeInOut } from 'utils/motion/fade-in-out';
import { zoomOutIn } from 'utils/motion/zoom-out-in';
import useOnClickOutside from 'utils/hooks/use-on-click-outside';
import { ModalWrapper } from './modal.style';
import { useUI } from 'contexts/ui.context';

const Modal = ({ children, open, onClose }) => {
  const modalRootRef = useRef();
  const modalInnerRef = useRef();
  const { modalView } = useUI();

  let modalClassName = 'modal-full';

  if (modalView === 'DAILY_STAT_VIEW') {
    modalClassName = 'modal-full half-full';
  }

  if (modalView === 'WORKERS_MODAL_VIEW' || modalView === 'DAILY_STAT_VIEW') {
    modalClassName = 'modal-full half-full';
  }

  useOnClickOutside(modalInnerRef, () => onClose());

  useEffect(() => {
    if (modalInnerRef.current) {
      if (open) {
        disableBodyScroll(modalInnerRef.current);
        document.body.classList.add('modal-open');
      } else {
        enableBodyScroll(modalInnerRef.current);
        document.body.classList.remove('modal-open');
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open]);

  return (
    <Portal>
      <AnimatePresence>
        {open && (
          <ModalWrapper>
            <motion.div
              ref={modalRootRef}
              key="modal"
              initial="from"
              animate="to"
              exit="from"
              variants={fadeInOut(0.25)}
              className="modal-root"
            >
              <motion.div
                initial="from"
                animate="to"
                exit="from"
                variants={zoomOutIn()}
                className="modal-motion"
              >
                <div className={modalClassName}>
                  <div ref={modalInnerRef} className="modal-overflow">
                    {children}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </ModalWrapper>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
