import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const FooterWrapper = styled.footer`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.colors?.primary?.regular};

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    padding: 60px 20px;
  }
`;

export const FooterRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  a,
  p {
    color: ${themeGet('colors.text.light')};
  }

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const FooterTop = styled(FooterRow)`
  margin-bottom: 45px;

  a {
    display: block;
  }
`;

export const Block = styled.div`
  & > a,
  & > p {
    margin-bottom: 14px;
  }
`;

export const FooterBottom = styled(FooterRow)`
  align-items: center;

  & > ${Text} {
    grid-area: copyright;
  }

  ${Block}:first-of-type {
    grid-area: socials;
  }

  ${Block}:nth-of-type(2) {
    grid-area: chat;
  }

  ${Block}:last-of-type {
    grid-area: developedBy;
  }

  grid-template-areas:
    'socials'
    'chat'
    'developedBy'
    'copyright';

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    grid-template-areas:
      'socials chat'
      'developedBy copyright';
  }

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    grid-template-areas: 'copyright socials chat developedBy';
  }
`;

export const Heading = styled(Text)`
  font-size: ${themeGet('fontSizes.sm')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
`;

export const SocialsContainer = styled.div`
  display: flex;

  svg {
    margin-right: 20px;
  }
`;

export const ChatsContainer = styled.div`
  display: flex;

  p {
    margin-left: 10px;
  }
`;

export const Container = styled.a`
  display: flex;
  align-items: center;
  margin-right: 12px;

  span {
    margin-left: 10px;
  }
`;

export const CopyrightText = styled(Text)`
  align-self: end;
`;
