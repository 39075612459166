import { useTheme } from 'contexts/theme.context';
import LightIcon from 'components/icons/LightIcon';
import DarkIcon from 'components/icons/DarkIcon';
import { StyledSwitch } from './theme-switcher.style';

const SwitchButton = () => {
  const { theme, switchTheme } = useTheme();
  return (
    <StyledSwitch activeTheme={theme} onClick={switchTheme}>
      {theme === 'light' ? (
        <LightIcon color="#c1ccec" />
      ) : (
        <DarkIcon color="#c1ccec" />
      )}
    </StyledSwitch>
  );
};

export default SwitchButton;
