import ETCIconNoShadow from 'components/icons/ETCIconNoShadow';
import ETHIconNoShadow from 'components/icons/ETHIconNoShadow';

export const regularCoins = {
  eth: /^0x[a-zA-z0-9]{40}$/,
  etc: /^0x[a-zA-Z0-9]{40}$/
};

export const passwordRe =
  /^(?=.*[!@$&*#_-])(?=.*\d)(?=.*[a-z])(?=.*[!@$&*#_-])(?=.*[A-Z])[A-Za-z\d!@$&*#_-]{8,32}$/;

export const ModalEvents = {
  WALLET_MODAL: 'wallet_modal',
  WITHDRAWAL_MODAL: 'withdrawal_modal',
  CONFIRM_WITHDRAWAL_MODAL: 'confirm_withdrawal_modal',
  CONFIRM_WALLET_MODAL: 'confirm_wallet_modal'
};

export const getComponentIcon = (coin) => {
  switch (coin) {
    case 'etc':
      return <ETCIconNoShadow />;
    case 'eth':
      return <ETHIconNoShadow />;
    default:
      return <p>No component match</p>;
  }
};

export const getPort = (cur, reg) => {
  let port = 8888;
  if (cur === 'ETH' || cur === 'eth') {
    switch (reg) {
      case 'asia1':
        port = 8888;
        break;
      case 'eu1':
        port = 3401;
        break;
      case 'eu2':
        port = 3401;
        break;
      default:
        port = 8888;
        break;
    }
  }
  if (cur === 'ETC' || cur === 'etc') {
    switch (reg) {
      case 'asia1':
        port = 8808;
        break;
      case 'eu1':
        port = 3404;
        break;
      case 'eu2':
        port = 3404;
        break;
      default:
        port = 8808;
        break;
    }
  }

  return port;
};

export const coins = [
  // {
  //   value: 'ETH',
  //   label: 'ETH',
  //   icon: ETHIconNoShadow
  // },
  {
    value: 'ETC',
    label: 'ETC',
    icon: ETCIconNoShadow
  }
];

export const toggleOptions = [
  {
    value: 'day',
    label: 'toggleOptions.daily'
  },
  {
    value: 'hour',
    label: 'toggleOptions.hourly'
  }
];

export const minerSettings = [
  {
    label: 'PhoenixMiner',
    value: 'PhoenixMiner'
  },
  {
    label: 'TeamRedMiner',
    value: 'TeamRedMiner'
  },
  {
    label: 'NanoMiner',
    value: 'NanoMiner'
  },
  {
    label: 'GMiner',
    value: 'GMiner'
  },
  {
    label: 'LoLMiner',
    value: 'LoLMiner'
  },
  {
    label: 'NBMiner',
    value: 'NBMiner'
  },
  {
    label: 'T-rex Miner',
    value: 'TrexMiner'
  }
];

export const minerConfig = {
  currency: coins[0].value,
  team: 'PhoenixMiner',
  region: 'eu1',
  port: coins[0].value === 'ETH' ? 3401 : 3404
};

export const optionsRegions = [
  {
    label: 'Europe1',
    value: 'eu1'
  },
  {
    label: 'Europe2',
    value: 'eu2'
  },
  {
    label: 'Asia1',
    value: 'asia1'
  }
];

export const referralsOptions = [
  {
    label: 'dropdownOptions.referrals.active',
    value: true
  },
  {
    label: 'dropdownOptions.referrals.inactive',
    value: false
  }
];

export const dateOptions = [
  {
    label: 'dropdownOptions.date.today',
    value: 'today'
  },
  {
    label: 'dropdownOptions.date.yesterday',
    value: 'yesterday'
  },
  {
    label: 'dropdownOptions.date.lastWeek',
    value: 'lastWeek'
  },
  {
    label: 'dropdownOptions.date.lastMonth',
    value: 'lastMonth'
  }
];

export const SPACER = 20;
