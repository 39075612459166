import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { useUI } from 'contexts/ui.context';
import { useUser } from 'contexts/user.context';
import useCopyToClipboard from 'utils/hooks/use-copy-to-clipboard';

import Tooltip from 'components/common/tooltip/tooltip';
import CopyIcon from 'components/icons/CopyIcon';
import Heading from 'components/common/heading/heading';
import { Button } from 'components/common/button/button';
import { Text } from 'components/common/text/text';

import CloseIcon from 'components/icons/CloseIcon';

import {
  Wrapper,
  BlockWrapper,
  List,
  QrContainer,
  QrCodeWrapper,
  TextContainer,
  CopyButton,
  CloseButton
} from './second-view.style';

const SecondView = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [token] = useState(data?.secret);
  const [copied, copy] = useCopyToClipboard(700);
  const [, copyCodes] = useCopyToClipboard(700);
  const { closeModal } = useUI();

  return (
    <Wrapper>
      <CloseButton onClick={() => closeModal()}>
        <CloseIcon />
      </CloseButton>
      <Heading variant="h3" mb={4}>
        {t('twoFA.title')}
      </Heading>
      <Text size="md">{t('twoFA.qrText')}</Text>
      <QrContainer>
        <QrCodeWrapper>
          <QRCode
            size={154}
            value={`otpauth://totp/Minerall:${user?.email}?secret=${token}&issuer=Minerall`}
          />
        </QrCodeWrapper>

        <TextContainer>
          <Text color="primary.regular" weight="bold" size="up">
            {token}
          </Text>

          {copied ? (
            <Tooltip message={t('walletsBlock.tooltipText')}>
              <CopyIcon />
            </Tooltip>
          ) : (
            <CopyButton onClick={() => copy(token)}>
              <CopyIcon />
            </CopyButton>
          )}
        </TextContainer>
      </QrContainer>
      <Heading variant="h6" mb={3}>
        {t('twoFA.titleCode')}
      </Heading>
      <Text mb={3} size="xs">
        {t('twoFA.textCode')}
      </Text>

      {data?.recoveryCodes.length > 0 && (
        <BlockWrapper>
          <List>
            {data?.recoveryCodes.map((item) => (
              <span>{item}</span>
            ))}
          </List>
          <Button
            size="small"
            onClick={() => {
              copyCodes(data?.recoveryCodes.join(' '));
            }}
          >
            {t('twoFA.copy')}
          </Button>
        </BlockWrapper>
      )}
    </Wrapper>
  );
};

export default SecondView;
