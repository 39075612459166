import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { useUI } from 'contexts/ui.context';
import { ACCOUNT_UPDATE_TWOFA } from 'graphql/account.mutation';

import FirstView from './first-view/first-view';
import SecondView from './second-view/second-view';

const TwoFaModal = () => {
  const { updateToken, setUseTwoFa } = useUI();
  const [firstView, setView] = useState(true);
  const [data, setData] = useState(null);
  const [updateTwoFa, { loading }] = useMutation(ACCOUNT_UPDATE_TWOFA);

  const acceptHandler = async () => {
    if (updateToken === '') return null;
    const result = await updateTwoFa({
      variables: {
        input: { token: updateToken, active: true }
      },
      context: { clientName: 'private' }
    });

    if (result?.data?.updateTwoFA?.active) {
      setData(result?.data?.updateTwoFA);
      setUseTwoFa(true);
      setView(false);
    }
  };

  return firstView ? (
    <FirstView loading={loading} handler={acceptHandler} />
  ) : (
    <SecondView data={data} />
  );
};

export default TwoFaModal;
