import { useState, useRef } from 'react';
import useOnClickOutside from 'utils/hooks/use-on-click-outside';
import cx from 'classnames';
import DropArrowIcon from 'components/icons/DropArrowIcon';

import { motion, AnimatePresence } from 'framer-motion';
import { zoomInBottom } from 'utils/motion/zoom-in-bottom';
import {
  PopoverWrapper,
  PopoverHandler,
  PopoverContent
} from './popover.style';

const Popover = ({ className, handler, content, direction }) => {
  const [state, setState] = useState(false);
  const ref = useRef();

  const handleToggle = () => {
    setState((s) => !s);
  };

  useOnClickOutside(ref, () => setState(() => false));

  return (
    <PopoverWrapper
      className={cx('popover-wrapper', {
        [className]: className,
        [direction]: direction,
        active: state
      })}
      ref={ref}
    >
      <PopoverHandler onClick={handleToggle}>
        <span className="popover-text">{handler}</span>
        <span className="popover-drop-icon">
          <DropArrowIcon color="#c1ccec" />
        </span>
      </PopoverHandler>
      <AnimatePresence>
        {state && (
          <motion.div
            initial="from"
            animate="to"
            exit="from"
            variants={zoomInBottom()}
          >
            <PopoverContent>
              {content && (
                <div className="inner-wrap" onClick={handleToggle}>
                  {content}
                </div>
              )}
            </PopoverContent>
          </motion.div>
        )}
      </AnimatePresence>
    </PopoverWrapper>
  );
};

export default Popover;
