import { useState, useEffect, useMemo, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { fadeInOut } from 'utils/motion/fade-in-out';

import {
  StyledTooltip,
  StyledTooltipToggler,
  StyledTooltipMessage
} from './tooltip.style';

const Tooltip = ({ position = 'top', message = '', children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef(null);
  const style = useMemo(() => ({}), []);

  useEffect(() => {
    if (node.current && node.current.offsetWidth) {
      style.left = `${String(node.current.offsetWidth / 2)}px`;
    }
  }, [style]);

  return (
    <StyledTooltip>
      <StyledTooltipToggler
        onMouseOverCapture={() => setIsVisible(() => true)}
        onMouseOut={() => setIsVisible(() => false)}
        ref={node}
      >
        {children}
      </StyledTooltipToggler>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial="from"
            animate="to"
            exit="from"
            variants={fadeInOut()}
          >
            <StyledTooltipMessage className={position} style={style}>
              {message}
            </StyledTooltipMessage>
          </motion.div>
        )}
      </AnimatePresence>
    </StyledTooltip>
  );
};

export default Tooltip;
