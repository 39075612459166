const CaretDownIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.63318 1.64019L4.76822 5.07813C4.36843 5.55789 3.63157 5.55789 3.23178 5.07813L0.366819 1.64019C-0.175953 0.988859 0.287203 9.53674e-07 1.13504 9.53674e-07L6.86496 9.53674e-07C7.7128 9.53674e-07 8.17595 0.98886 7.63318 1.64019Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretDownIcon;
