import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { cloneDeep, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';

import { GET_USER_SETTINGS } from 'graphql/settings.query';
import { CREATE_WALLET, UPDATE_WALLET } from 'graphql/wallet.mutation';

import { getFieldProps } from 'utils';
import { floatToBigInt } from 'utils/helpers/converters';
import { ModalEvents, regularCoins } from 'utils/constant';

import { useCoins } from 'contexts/coins.context';
import { useUser } from 'contexts/user.context';
import { useWallet } from 'contexts/wallet/wallet.context';
import { useNotify } from 'contexts/notification.context';
import { AddressField, NameField } from './custom-fields';
import {
  FixedAmountField,
  NetworkTypeField
} from 'components/wallets/wallet-modals/modals/custom-fields';
import ToggleButton from 'components/wallets/components/toggle-button/toggle-button';
import Dropdown from 'components/common/form/dropdown/dropdown';

import { FormField, FormRow, Heading, Wrapper } from 'assets/styles/form.style';

import DataModal from '../data-modal';

const WalletModal = () => {
  const { t } = useTranslation();
  const { editWallet, addWallet } = useWallet();
  const { isAuthenticated } = useUser();
  const { setMessage } = useNotify();
  const { coins } = useCoins();
  const [optionsCoins, setOptionsCoins] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [coinId, setCoinId] = useState('');

  const [loadUserSettings, { data, loading: loadingSettings }] = useLazyQuery(
    GET_USER_SETTINGS,
    {
      context: { clientName: 'private' }
    }
  );

  useEffect(() => {
    if (isAuthenticated && !loadingSettings && coinId) {
      loadUserSettings({
        variables: { coinId }
      });
    }
  }, [coinId, isAuthenticated, loadUserSettings, loadingSettings]);

  useEffect(() => {
    if (optionsCoins === '' && coins.length > 0) {
      setOptionsCoins(
        coins.map((item) => ({ value: item.id, label: item.tag.toUpperCase() }))
      );
    }
  }, [coins, optionsCoins]);

  const [createWallet, { loading }] = useMutation(CREATE_WALLET);
  const [updateWallet] = useMutation(UPDATE_WALLET);

  const addHandler = async (values) => {
    const modifyValues = cloneDeep(values);

    if (values.autoWithdraw !== null) {
      modifyValues.autoWithdraw.fixedAmount = floatToBigInt(
        values.autoWithdraw.fixedAmount
      );
    }

    const result = await createWallet({
      variables: {
        input: modifyValues
      },
      context: { clientName: 'private' }
    });

    if (result?.data?.createWallet) {
      addWallet(result?.data?.createWallet);
      setMessage('notifications.wallets.createWallet', {
        status: 'success'
      });
    }
  };

  const editModeHandler = (mode) => {
    mode ? setEditMode(true) : setEditMode(false);
  };

  const editHandler = async (values) => {
    const modifyValues = omit(values, [
      'active',
      'coinId',
      'createdAt',
      'id',
      'networkType',
      'autoWithdraw.__typename',
      '__typename'
    ]);

    if (values.autoWithdraw !== null) {
      modifyValues.autoWithdraw.fixedAmount = floatToBigInt(
        values.autoWithdraw.fixedAmount
      );
    }

    const result = await updateWallet({
      variables: {
        walletId: values.id,
        input: modifyValues
      },
      context: { clientName: 'private' }
    });

    if (result?.data?.updateWallet) {
      editWallet(result?.data?.updateWallet);
      setMessage('notifications.wallets.changesSaved', {
        status: 'success'
      });
    }
  };

  return (
    <Formik
      validateOnBlur
      enableReinitialize
      initialValues={{
        coinId: optionsCoins !== '' ? optionsCoins[0].value : '',
        name: '',
        address: '',
        networkType: 'native',
        autoWithdraw: {
          type: 'day',
          fixedAmount: '0'
        }
      }}
      validationSchema={() => {
        return yup.lazy((values) => {
          return yup.object().shape({
            coinId: yup
              .string()
              .required(t('walletsBlock.walletFields.coinRequired')),
            name: yup
              .string()
              .required(t('walletsBlock.walletFields.nameRequired'))
              .min(4, t('walletsBlock.walletFields.nameMin'))
              .max(50, t('walletsBlock.walletFields.nameMax')),
            address: yup
              .string()
              .required(t('walletsBlock.walletFields.addressRequired'))
              .matches(
                regularCoins.eth,
                t('walletsBlock.walletFields.addressValid')
              ),
            networkType: yup.mixed().oneOf(['native', 'near']),
            autoWithdraw:
              values.autoWithdraw !== null
                ? yup.object().shape({
                    type: yup.mixed().oneOf(['day', 'hour']),
                    fixedAmount: yup.number()
                  })
                : yup.string().nullable()
          });
        });
      }}
    >
      {(formik) => {
        setCoinId(formik.values.coinId);
        return (
          <DataModal
            name="wallet"
            event={ModalEvents.WALLET_MODAL}
            loading={loading}
            onCreate={addHandler}
            onEdit={editHandler}
            editModeFunc={editModeHandler}
          >
            {(id) => (
              <Wrapper>
                <Heading>{t('walletsBlock.modal.title')}</Heading>
                <FormRow>
                  <FormField>
                    <Dropdown
                      name="coinId"
                      disabled={editMode}
                      options={optionsCoins}
                      {...getFieldProps(formik, 'coinId')}
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <NameField id={id} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <AddressField id={id} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <NetworkTypeField
                      editMode={editMode}
                      settings={data?.userSettings}
                    />
                  </FormField>
                </FormRow>
                <ToggleButton
                  name="autoWithdraw.type"
                  formik={formik}
                  editMode={editMode}
                  {...getFieldProps(formik, 'autoWithdraw.type')}
                >
                  <FixedAmountField
                    name="autoWithdraw.fixedAmount"
                    editMode={editMode}
                    settings={data?.userSettings}
                    {...getFieldProps(formik, 'autoWithdraw.fixedAmount')}
                  />
                </ToggleButton>
              </Wrapper>
            )}
          </DataModal>
        );
      }}
    </Formik>
  );
};

export default WalletModal;
