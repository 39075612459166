export const dataReducer = (state, action) => {
  switch (action.type) {
    case 'SET_WORKERS': {
      return {
        ...state,
        workers: action.workers,
        workersFiltered: action.workers.filter((item) => item.active)
      };
    }
    case 'SET_STATISTICS': {
      return {
        ...state,
        statistics: action.statistics
      };
    }
    case 'SET_ACTIVE_DAY': {
      return {
        ...state,
        statActiveDay: action.day
      };
    }
    case 'SET_ACTIVE_COIN': {
      return {
        ...state,
        activeCoin: action.coin
      };
    }
    case 'WORKERS_FILTER_ACTIVE': {
      return {
        ...state,
        workersFiltered: state.workers.filter((item) => item.active),
        workersActiveTab: 'active'
      };
    }
    case 'WORKERS_FILTER_INACTIVE': {
      return {
        ...state,
        workersFiltered: state.workers.filter((item) => !item.active),
        workersActiveTab: 'inactive'
      };
    }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
