import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Button } from 'components/common/button/button';

export const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const Title = styled.h3`
  font-size: ${themeGet('fontSizes.lg')}px;
  order: 0;
`;

export const Tabs = styled.div`
  display: flex;
  gap: 4px;
  order: 10;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: ${(props) => props.mediaWidth}px) {
    order: 1;
  }
`;

export const TabButton = styled(Button)(
  ({ isInactive }) => css`
    background-color: ${isInactive && 'transparent'};
    color: ${isInactive && themeGet('colors.text.default')};
    box-shadow: ${isInactive && 'none'};
    transition: all 0.25 ease;

    &:hover {
      background-color: ${isInactive && 'transparent'};
    }

    &:active,
    &:focus {
      border-color: ${isInactive && 'transparent'};
    }
  `
);
