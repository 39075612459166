const BitsMediaIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.8566 6.00555C14.3344 4.66482 9.52225 4.66482 5 6.00555V13.26V13.7456C5 16.8039 7.64523 19.5723 11.9283 21C16.2042 19.5723 18.8566 16.7604 18.8566 13.7456V13.26V6.00555ZM14.8997 15.5791C14.411 15.9362 13.8844 16.0117 13.5915 16.0537C13.5456 16.0603 13.5054 16.066 13.472 16.0719C13.4248 16.0719 13.356 16.0779 13.2744 16.085L13.2744 16.085C13.1554 16.0954 13.0093 16.1081 12.8632 16.1081V17.659H11.9283V16.1516H11.1964V17.659H10.2542V16.1516H8.42068L8.6236 15.0138H9.19613C9.39905 15.0138 9.47877 15.0138 9.56573 14.9268C9.64545 14.8471 9.64545 14.7239 9.64545 14.6442V10.7307C9.64545 10.4916 9.64545 10.4046 9.52225 10.2886C9.39905 10.1654 9.15989 10.1654 8.99321 10.1654H8.42068V9.18707H10.2542V7.67965H11.1964V9.18707H11.9283V7.67965H12.8632V9.2233C13.5589 9.26679 14.088 9.38999 14.5373 9.75235C15.1026 10.2017 15.1026 10.9336 15.0664 11.1438C15.0664 11.3033 14.9794 11.6729 14.82 11.8758C14.6533 12.1149 14.2909 12.3251 14.2909 12.3251C14.2909 12.3251 14.7402 12.4048 15.0664 12.6512C15.3925 12.8904 15.6316 13.3035 15.5954 13.9485C15.5954 14.5645 15.4722 15.2167 14.8997 15.5791ZM13.3565 11.1005C13.3565 11.8324 12.5811 12.0499 11.9651 12.0789C11.8504 12.084 11.7213 12.0855 11.6022 12.0869H11.6021H11.6021C11.3826 12.0894 11.1969 12.0916 11.1969 12.1151V10.2815C11.1969 10.2815 11.523 10.2453 11.8056 10.2453C12.3782 10.2453 13.3565 10.325 13.3565 11.1005ZM11.2333 13.0135C11.2333 13.0135 11.8493 12.9773 12.2914 13.0135C12.9437 13.0715 13.8133 13.2454 13.8423 14.0354C13.8786 14.8615 12.9364 15.0137 12.2914 15.05C11.8493 15.079 11.2333 15.05 11.2333 15.05V13.0135Z"
        fill="white"
      />
    </svg>
  );
};

export default BitsMediaIcon;
