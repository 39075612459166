import { useTheme } from 'contexts/theme.context';
import CloseIcon from 'components/icons/CloseIcon';
import { Button } from './close-button.styles';

const CloseButton = ({ handler, order = 'initial' }) => {
  const { theme } = useTheme();
  const color = theme === 'light' ? '#000' : '#fff';

  return (
    <Button type="button" onClick={handler} order={order}>
      <CloseIcon color={color} />
    </Button>
  );
};

export default CloseButton;
