const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return {};
  }
};

export const getToken = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const token = localStorage.getItem('access_token');
  if (token) {
    const jwtPayload = parseJwt(token);
    if (Object.keys(jwtPayload).length > 0) {
      if (jwtPayload.exp < new Date() / 1000) {
        return null;
      }
    }
  }

  return token;
};

export const checkToken = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const token = localStorage.getItem('access_token');
  if (token) {
    const jwtPayload = parseJwt(token);
    if (Object.keys(jwtPayload).length > 0) {
      if (jwtPayload.exp > new Date() / 1000) {
        return 'refresh';
      }
      if (jwtPayload.exp < new Date() / 1000) {
        localStorage.removeItem('access_token');
        return 'expired';
      }
    }
  }
};
