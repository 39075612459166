import { forwardRef } from 'react';
import styled, { keyframes } from 'styled-components';
import css from '@styled-system/css';
import { variant } from 'styled-system';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const StyledButton = styled.button(
  (props) =>
    css({
      width: props?.fullwidth ? '100%' : 'auto',
      px: '25px',
      py: '16px',
      fontSize: ['up'],
      fontWeight: 'button',
      alignSelf: props?.alignSelf ? props.alignSelf : 'flex-start',
      cursor: props?.disabled ? 'not-allowed' : 'pointer',
      color: 'text.light',
      bg: props?.disabled ? 'text.regular' : 'primary.regular',
      boxShadow: 'button',
      whiteSpace: 'nowrap',
      transition: 'all 0.3s ease',
      borderRadius: 'base',
      lineHeight: '24px',

      '&:hover': {
        bg: props?.disabled ? 'text.regular' : 'button.hover'
      },
      '&:active': {
        borderColor: 'button.active'
      }
    }),
  {
    position: 'relative',
    appearance: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: 'inherit',
    border: '2px solid transparent',

    '> svg': {
      marginRight: '12px'
    },

    '&:focus': {
      outline: 'none'
    }
  },
  variant({
    variants: {
      secondary: {
        color: 'text.regular',
        bg: 'primary.light',
        fontSize: 'base',
        boxShadow: 'none',

        '&:hover': {
          color: 'text.light'
        }
      },
      gray: {
        bg: 'button.variant.gray.regular',
        color: 'button.variant.gray.icon'
      },
      light: {
        color: 'text.regular',
        bg: 'secondary.regular',
        boxShadow: 'buttonLight',

        '> svg': {
          position: 'absolute',
          left: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
          margin: 0
        },

        '&:hover': {
          backgroundColor: 'transparent',
          color: 'button.regular'
        }
      },
      success: {
        pl: '52px',
        pr: '20px',
        fontSize: 'base',
        bg: 'button.variant.success.regular',

        '&:disabled': {
          cursor: 'not-allowed',
          bg: 'text.regular',
          '&:hover': {
            bg: 'text.regular'
          }
        },

        '> svg': {
          position: 'absolute',
          left: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
          margin: 0
        },

        '&:hover': {
          bg: 'button.variant.success.hover'
        },
        '&:active': {
          borderColor: 'button.variant.success.active'
        },
        '&:focus': {
          borderColor: 'button.variant.success.active'
        }
      },
      outlined: {
        color: 'primary.regular',
        bg: 'transparent',
        border: '1px solid',
        fontSize: 'base',
        borderColor: 'button.variant.gray.regular',
        boxShadow: 'none',
        textTransform: 'none',
        '&.upper': {
          textTransform: 'uppercase'
        },
        '&.active': {
          bg: 'button.hover',
          borderColor: 'button.hover',
          color: 'text.light',
          cursor: 'default'
        },
        '&:hover:not(.active)': {
          borderColor: 'primary.hover',
          color: 'primary.hover',
          bg: 'transparent'
        }
      }
    }
  }),
  variant({
    prop: 'size',
    variants: {
      small: {
        px: '25px',
        py: '16px',
        fontSize: 'sm',
        lineHeight: '17px'
      },
      middle: {
        px: '18px',
        py: '17px',
        fontSize: 'up',
        lineHeight: '23px'
      }
    }
  })
);

const rotate = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

const Spinner = styled.div`
  width: 14px;
  height: 14px;
  margin-left: 10px;
  border: 2px solid ${(props) => props.theme.colors.text.light};
  border-top: 2px solid
    ${(props) => (props?.color ? props.color : 'transparent')};
  border-radius: 50%;
  transition-property: transform;
  animation-name: ${rotate};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const Button = forwardRef(
  ({ children, disabled, icon, loading = false, ...props }, ref) => (
    <StyledButton ref={ref} {...props} disabled={disabled}>
      {icon && icon}
      {children}
      {loading && <Spinner />}
    </StyledButton>
  )
);
