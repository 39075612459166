import { useUI } from 'contexts/ui.context';
import WorkersModal from 'components/workers-modal/workers-modal';
import DailyStatisticModal from 'components/table-block/daily-statistic/daily-statistic-modal/daily-statistic-modal';
import TwoFaModal from 'components/two-fa-modal/two-fa-modal';
import ResetPassModal from './pass-reset-modal';
import VerifyPassModal from './verify-modal';
import Modal from './modal';

const ManagedModal = () => {
  const {
    verifyStatus,
    useTwoFa,
    displayModal,
    closeModal,
    modalView,
    setActiveTwoFaToggler
  } = useUI();

  const handleClose = () => {
    closeModal();

    if (
      modalView === 'VERIFY_MODAL_VIEW' ||
      modalView === 'TWO_FA_MODAL_VIEW'
    ) {
      if (useTwoFa !== true) {
        if (verifyStatus) {
          setActiveTwoFaToggler(false);
        } else {
          setActiveTwoFaToggler(true);
        }
      }
    }
  };

  return (
    <Modal open={displayModal} onClose={handleClose}>
      {modalView === 'WORKERS_MODAL_VIEW' && <WorkersModal />}
      {modalView === 'DAILY_STAT_VIEW' && <DailyStatisticModal />}
      {modalView === 'TWO_FA_MODAL_VIEW' && <TwoFaModal />}
      {modalView === 'RESET_PASS_MODAL_VIEW' && <ResetPassModal />}
      {modalView === 'VERIFY_MODAL_VIEW' && <VerifyPassModal />}
    </Modal>
  );
};

export default ManagedModal;
