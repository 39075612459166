const addItem = (state, action, name) => {
  return [...state[name], action.payload];
};

const editItem = (state, action, name) => {
  return state[name].map((item) =>
    item.id === action.payload.id ? action.payload : item
  );
};

const removeItem = (state, action, name) => {
  return state[name].reduce((acc, item) => {
    if (item.id === action.payload.id) {
      return [...acc];
    }
    return [...acc, item];
  }, []);
};

const removeAutoWithdrawItem = (state, action) => {
  return state.wallets.map((item) =>
    item.id === action.payload.id ? { ...item, autoWithdraw: null } : item
  );
};

export const walletReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TRANSACTIONS': {
      return {
        ...state,
        transactions: action.transactions
      };
    }
    case 'EDIT_WITHDRAWAL':
      return { ...state, withdrawals: editItem(state, action, 'withdrawals') };
    case 'ADD_WALLET':
      return {
        ...state,
        wallets: addItem(state, action, 'wallets')
      };
    case 'ADD_WALLETS':
      return {
        ...state,
        wallets: action.payload
      };
    case 'EDIT_WALLET':
      return {
        ...state,
        wallets: editItem(state, action, 'wallets')
      };
    case 'REMOVE_WITHDRAWAL':
      return {
        ...state,
        wallets: removeAutoWithdrawItem(state, action)
      };
    case 'REMOVE_WALLET':
      return {
        ...state,
        wallets: removeItem(state, action, 'wallets')
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
