export function handleResponse(response) {
  if (response.ok) return response.json();
  if (!response.ok) return Promise.reject(response);

  throw new Error('Network response was not ok.');
}

export const signInAPI = async (values) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/login`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }
  ).then(handleResponse);
  return res;
};

export const tokenRefreshAPI = async () => {
  const token = localStorage.getItem('access_token');

  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/token/refresh`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : ''
      },
      body: JSON.stringify({})
    }
  ).then(handleResponse);
  return res;
};

export const getOAuthRedirectAPI = async () => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/oauth?${new URLSearchParams({
      redirect: process.env.GATSBY_SITE_URL,
      provider: process.env.GATSBY_PROVIDER_GOOGLE,
      state: process.env.GATSBY_PROVIDER_STATE
    })}`,
    {
      method: 'GET'
    }
  ).then(handleResponse);
  return res;
};

export const authorizeUserOAuthAPI = async (values) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/oauth`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }
  ).then(handleResponse);
  return res;
};

export const signInConfirmAPI = async (values) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/login/confirm`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }
  ).then(handleResponse);

  return res;
};

export const signUpAPI = async (values) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/register`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }
  ).then(handleResponse);

  return res;
};

export const signUpConfirmAPI = async (token) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/register/confirm`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    }
  ).then(handleResponse);

  return res;
};

export const resetPassAPI = async (values) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/password/reset`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }
  ).then(handleResponse);

  return res;
};

export const resetPassConfirmAPI = async (values) => {
  const res = await fetch(
    `${process.env.GATSBY_REST_API_AUTH_URL}/auth/password/reset/confirm`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    }
  ).then(handleResponse);

  return res;
};
