const TelegramIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.3726 24 0 18.6274 0 12C0 5.3726 5.3726 0 12 0C18.6274 0 24 5.3726 24 12ZM8.03575 10.9008C6.83365 11.4256 5.59855 11.9649 4.46913 12.587C3.87939 13.0188 4.66319 13.3243 5.39855 13.6108C5.51545 13.6563 5.6311 13.7014 5.73985 13.7464C5.83035 13.7742 5.92235 13.8035 6.0156 13.8332C6.83345 14.0938 7.74535 14.3843 8.53935 13.9473C9.84365 13.198 11.0745 12.3318 12.3045 11.4662C12.7074 11.1826 13.1103 10.8992 13.5156 10.6199C13.5346 10.6077 13.556 10.5938 13.5794 10.5786C13.9247 10.3548 14.7012 9.85145 14.4139 10.545C13.7347 11.2878 13.0072 11.9454 12.2757 12.6065C11.7827 13.0521 11.288 13.4993 10.805 13.9753C10.3843 14.3171 9.94745 15.0044 10.4185 15.483C11.5034 16.2425 12.6054 16.9836 13.7066 17.7243C14.065 17.9653 14.4233 18.2064 14.7809 18.448C15.387 18.9319 16.3343 18.5404 16.4675 17.7843C16.5268 17.4364 16.5863 17.0886 16.6458 16.7407C16.9746 14.8184 17.3034 12.8954 17.594 10.9668C17.6335 10.6642 17.6782 10.3617 17.723 10.059C17.8316 9.3255 17.9403 8.59105 17.9743 7.85355C17.8868 7.11755 16.9943 7.2794 16.4977 7.4449C13.9451 8.4162 11.418 9.4595 8.90095 10.5212C8.6158 10.6475 8.32675 10.7737 8.03575 10.9008Z"
        fill="white"
      />
    </svg>
  );
};

export default TelegramIcon;
