import styled from 'styled-components';

export const BannerBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #FF4640;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  
  svg {
    align-self: flex-start;
    width: 100%;
    height: 100%;
    max-width: 27px;
    max-height: 24px;
  }
`;
