import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';

import DropArrowIcon from 'components/icons/DropArrowIcon';
import useOnClickOutside from 'utils/hooks/use-on-click-outside';
import { getByValue, truncateString } from 'utils';

import { zoomInBottom } from 'utils/motion/zoom-in-bottom';

import {
  DropdownWrapper,
  Label,
  List,
  ListItem,
  Options
} from './dropdown.style';

const Dropdown = ({
  name,
  value,
  label = '',
  disabled = false,
  variant = 'default',
  options,
  error = false,
  onChange
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const dropdownRef = useRef();
  const handleToggle = () => setIsOpen((r) => !r);

  const onClickHandler = (item) => {
    setSelectedOption(item);
    if (isFunction(onChange)) {
      onChange({ target: { name, value: item.value } });
    }
    setIsOpen(() => false);
  };

  useEffect(() => {
    if (value) {
      setSelectedOption(getByValue(options, value));
    }
  }, [options, value]);

  useOnClickOutside(dropdownRef, () => setIsOpen(() => false));

  return (
    <DropdownWrapper
      className={cx(variant, {
        'is--disabled': disabled,
        error
      })}
      ref={dropdownRef}
    >
      <Options
        className={cx({
          open: isOpen
        })}
        onClick={!disabled ? handleToggle : undefined}
      >
        <Label>
          {selectedOption !== null
            ? truncateString(t(selectedOption?.label), 24)
            : truncateString(label, 24)}
        </Label>
        <DropArrowIcon color="#c1ccec" />
      </Options>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="from"
            animate="to"
            exit="from"
            variants={zoomInBottom()}
          >
            <List>
              {options.length > 0 ? (
                options.map((item) => (
                  <ListItem
                    className={cx({
                      active:
                        selectedOption !== null &&
                        selectedOption?.value === item.value
                    })}
                    onClick={() => onClickHandler(item)}
                    key={item.value}
                  >
                    {truncateString(t(item.label), 27)}
                  </ListItem>
                ))
              ) : (
                <ListItem>{t('dropdownOptions.noOptions')}</ListItem>
              )}
            </List>
          </motion.div>
        )}
      </AnimatePresence>
    </DropdownWrapper>
  );
};

export default Dropdown;
