import { StyledText } from './text.style';

export const Text = ({
  size = 'base',
  weight = 'regular',
  color = 'text.default',
  children,
  ...props
}) => {
  return (
    <StyledText size={size} weight={weight} $color={color} {...props}>
      {children}
    </StyledText>
  );
};
