export const baseTheme = {
  breakpoints: ['767px', '991px', '1024px', '1280px'],
  mediaQueries: {
    mobileOnly: 'screen and (max-width: 766px)',
    tabletPortraitUp: 'screen and (min-width: 767px)',
    tabletLandscapeUp: 'screen and (min-width: 991px)',
    desktopUp: 'screen and (min-width: 1024px)',
    bigDesktopUp: 'screen and (min-width: 1280px)'
  },

  space: [0, 5, 10, 20, 40, 60, 80, 120, 100],
  sizes: [1320],

  fontSizes: {
    xxs: 8,
    xs: 12,
    sm: 14,
    base: 16,
    up: 18,
    md: 21,
    mdUp: 24,
    lg: 36,
    xl: 44
  },
  fontWeights: {
    regular: 400,
    body: 400,
    heading: 700,
    button: 700,
    light: 300,
    semi: 600,
    bold: 700
  },
  fonts: {
    body: 'Inter, sans-serif',
    menu: 'Inter, sans-serif',
    heading: 'Inter, sans-serif'
  },

  // Custom Theme keys
  customs: {
    transition: '0.25s ease-in-out'
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  radii: {
    base: '8px',
    small: '6px',
    medium: '30px'
  }
};

export default baseTheme;
