import { memo } from 'react';

import UserIcon from 'components/icons/UserIcon';
import LangSwitcher from 'layouts/lang-switcher/lang-switcher';
import ThemeSwitcher from 'layouts/theme-switcher/theme-switcher';
import { ActionsWrapper, ActionButton } from './actions.style';
import AuthMenu from './auth-menu';

const Actions = ({ isAuthenticated, onLogout }) => {
  return (
    <ActionsWrapper>
      <ActionButton>
        <ThemeSwitcher />
      </ActionButton>
      <ActionButton>
        <LangSwitcher />
      </ActionButton>
      <ActionButton>
        <AuthMenu
          onLogout={onLogout}
          isAuthenticated={isAuthenticated}
          icon={<UserIcon color="#6487FF" />}
        />
      </ActionButton>
    </ActionsWrapper>
  );
};

export default memo(Actions);
