import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';

export const StyledText = styled.p`
  color: ${(props) => themeGet(`colors.${props.$color}`)};
  font-size: ${(props) => themeGet(`fontSizes.${props.size}`)}px;
  font-weight: ${(props) => themeGet(`fontWeights.${props.weight}`)};
  ${space};
`;
