import { useCallback } from 'react';
import cx from 'classnames';

import Popover from 'components/popover/popover';
import { useTheme } from 'contexts/theme.context';
import { List, ListItem, ListItemText } from './lang-switcher.style';

function LangSwitcher({ type = 'popover' }) {
  const { language, switchLanguage, languages } = useTheme();

  const handleLocaleSwitch = (code) => () => {
    switchLanguage(code);
  };

  const LanguageList = useCallback(
    () => (
      <List className={type}>
        {languages.map((x) => (
          <ListItem
            key={x}
            className={cx({
              active: language === x.toLowerCase()
            })}
            onClick={handleLocaleSwitch(x)}
          >
            <ListItemText>{x}</ListItemText>
          </ListItem>
        ))}
      </List>
    ),
    [handleLocaleSwitch]
  );

  return type === 'popover' ? (
    <Popover
      handler={language.toUpperCase()}
      direction="right"
      content={<LanguageList />}
    />
  ) : (
    <LanguageList />
  );
}

export default LangSwitcher;
