import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { typography, space } from 'styled-system';
import { Link as GatsbyLink } from 'gatsby';

export const IconWrapper = styled.div`
  display: flex;
  margin-right: 6px;
`;

export const Offer = styled.p`
  font-size: ${themeGet('fontSizes.base')}px;
  font-weight: ${themeGet('fontWeights.regular')};
  text-align: ${(props) => (props.align === 'center' ? 'center' : 'left')};

  span {
    margin-right: 10px;
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: ${themeGet('radii.base')};
  border: 0;
  width: min(320px, 100vw - 40px);
  background-color: ${themeGet('colors.white')};

  ${space};

  form {
    ${Offer} {
      margin-top: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const Container = styled.div`
  padding: 0 20px;
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.h4`
  font-family: ${themeGet('fonts.heading')};
  font-size: ${themeGet('fontSizes.md')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  line-height: 2.5rem;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-size: ${themeGet('fontSizes.md')}px;

  ${space};

  strong {
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

const cssColumnsGridStyles = (col) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${col}, 1fr)`,
  gridGap: '20px'
});

const cssFlexStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr min-content',
  gridGap: '20px'
};

export const FormRow = styled.div`
  ${({ columns }) => columns && cssColumnsGridStyles(columns)}
  ${({ flex }) => flex && cssFlexStyles}

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:last-of-type {
    margin-bottom: 20px;
  }

  &.ext {
    margin-top: 35px;
    margin-bottom: 40px;
  }
`;

export const FormFieldsWrapper = styled.div`
  ${FormRow} {
    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  &.withMargin {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
`;

export const LabelError = styled.label`
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: ${themeGet('fontSizes.sm')}px;
  color: ${themeGet('colors.alerts.error')};
`;

export const FormField = styled.div`
  position: relative;

  &.fullWidth {
    grid-column: span 2;

    ${LabelError} {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`;

export const FormCover = styled.div`
  padding: 20px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};
`;

export const LabelInput = styled.label`
  display: block;
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-transform: uppercase;
  line-height: 24px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const SmallButton = styled(GatsbyLink)`
  display: block;
  cursor: pointer;
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.linkColor')};
  line-height: 2;
  transition: color 0.25s ease;
`;

export const OptionalText = styled.p`
  font-size: ${themeGet('fontSizes.sm')}px;
`;

export const InfoText = styled.div`
  padding: 13px 30px;
  border-radius: ${themeGet('radii.base')};
  font-size: ${themeGet('fontSizes.sm')}px;
  background-color: ${themeGet('colors.primary.regular')};
  text-align: center;
  color: ${themeGet('colors.text.light')};
  line-height: 1.3;
`;

export const LinkButton = styled(GatsbyLink)`
  background-color: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
  padding: 0;
  color: ${themeGet('colors.linkColor')};
  font-size: ${themeGet('fontSizes.base')}px;
  font-weight: ${themeGet('fontWeights.base')};
  text-transform: none;
  cursor: pointer;

  ${typography};

  svg {
    margin-right: 6px;
    vertical-align: middle;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('colors.button.variant.gray.regular')};
  color: ${themeGet('colors.button.variant.gray.icon')};
  width: 60px;
  height: 60px;
  border-radius: ${themeGet('radii.base')};
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  ${(props) => (props.noGap ? 'grid-gap: 0;' : 'grid-gap: 20px;')}
  width: min(320px, 100vw - 40px);
`;

export const Action = styled.div`
  display: grid;
`;

export const With = styled.div`
  button {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
