import { useState } from 'react';

import { FormField, Input, LabelEye } from 'components/common/form/input';
import EyeOffIcon from '../icons/EyeOffIcon';
import EyeIcon from '../icons/EyeIcon';

export const PasswordInput = ({ ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <FormField>
      <Input type={show ? 'text' : 'password'} id={rest.name} {...rest} />
      <LabelEye onClick={() => setShow((p) => !p)} htmlFor={rest.name}>
        {show ? <EyeOffIcon /> : <EyeIcon />}
      </LabelEye>
    </FormField>
  );
};
