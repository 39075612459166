import styled from 'styled-components';

export const LogoBox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LogoImage = styled.img({
  display: 'block',
  maxWidth: 172
});
