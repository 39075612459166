import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PopoverWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &.active {
    .popover-drop-icon {
      transform: rotate(180deg);
    }
  }

  svg,
  svg path {
    transition: all 0.25s ease;
  }

  &.right {
    .popover-content {
      left: auto;
      right: 0;
    }
  }
`;

export const PopoverHandler = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 12px;
  border-radius: ${themeGet('radii.base')};
  background-color: ${themeGet('colors.primary.light')};
  color: ${themeGet('text.regular')};
  font-weight: ${themeGet('fontWeights.bold')};
  font-size: ${themeGet('fontSizes.xs')}px;

  .popover-drop-icon {
    margin-left: 18px;
  }
`;

export const PopoverContent = styled.div`
  position: absolute;
  left: 0px;
  top: calc(100% + 4px);
  display: block;
  width: 100%;
  padding: 18px 10px;
  text-align: center;
  border-radius: ${themeGet('radii.base')};
  background-color: ${themeGet('colors.primary.light')};
  box-shadow: ${themeGet('shadows.popover')};
  z-index: 99;
`;
