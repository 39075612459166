import { gql } from '@apollo/client';

export const CREATE_WALLET = gql`
  mutation ($input: WalletCreateInput!) {
    createWallet(input: $input) {
      id
      coinId
      active
      name
      address
      networkType
      createdAt
      autoWithdraw {
        type
        fixedAmount
      }
      createdAt
    }
  }
`;

export const UPDATE_WALLET = gql`
  mutation ($walletId: ObjectID!, $input: WalletUpdateInput!) {
    updateWallet(walletId: $walletId, input: $input) {
      id
      coinId
      active
      name
      address
      networkType
      createdAt
      autoWithdraw {
        type
        fixedAmount
      }
      createdAt
    }
  }
`;

export const DELETE_WALLET = gql`
  mutation ($walletId: ObjectID!) {
    deleteWallet(walletId: $walletId)
  }
`;

export const ACTIVATE_WALLET = gql`
  mutation ($input: WalletActivateInput!) {
    activateWallet(input: $input)
  }
`;

export const REQUEST_WALLET_ACTIVATION = gql`
  mutation ($walletId: ObjectID!) {
    requestWalletActivation(walletId: $walletId)
  }
`;
