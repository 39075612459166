import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTableWrapper = styled.div`
  position: relative;
`;

export const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 40px;
  background-color: ${themeGet('colors.body.bg')};
  box-shadow: ${themeGet('shadows.section')};
  border-radius: ${themeGet('radii.base')};

  p {
    color: ${themeGet('colors.text.regular')};
    font-size: ${themeGet('fontSizes.base')}px;
    font-weight: ${themeGet('fontWeights.bold')};
  }
`;

export const StyledOverflow = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  transform: rotateX(180deg);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  > * {
    transform: rotateX(180deg);
  }
`;

export const StyledTable = styled.div`
  display: inline-block;
  min-width: 100%;

  @media ${themeGet('mediaQueries.mobileOnly')} {
    padding-top: 20px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const StyledTableRow = styled.div`
  display: grid;
  border-radius: ${themeGet('radii.base')};
  grid-template-columns: repeat(
    ${(props) => props.columns || 5},
    minmax(180px, 1fr)
  );

  /* padding-left: 10px;
  padding-right: 10px; */
`;

export const StyledTableCell = styled.div`
  line-height: 17px;
  font-size: ${themeGet('fontSizes.sm')}px;
  color: ${(props) => themeGet(props.color)};

  &.table-weight-bold {
    font-weight: ${themeGet('fontWeights.bold')};
  }

  &.table-alert-warning {
    color: ${themeGet('colors.alerts.warning')};
  }

  &.table-cell-inactive {
    color: ${themeGet('colors.text.inactive')};
  }

  &.table-alert-error {
    color: ${themeGet('colors.alerts.error')};
  }
`;

export const StyledTableHead = styled.div`
  ${StyledTableCell} {
    padding: 0 20px 20px;
  }

  .table-column-sorters {
    display: flex;
    align-items: center;

    .table-caret {
      line-height: 0;
      color: ${themeGet('colors.text.regular')};
    }

    &.ascending,
    &.descending {
      .table-caret {
        color: ${themeGet('colors.primary.regular')};
      }
    }

    &.ascending {
      .table-caret {
        transform: rotate(180deg);
      }
    }

    .table-column-sorter {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      line-height: 1;
    }

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .has-sortable {
    cursor: pointer;
  }

  .table-column-title {
    text-transform: uppercase;
    font-weight: ${themeGet('fontWeights.bold')};
    color: ${themeGet('colors.primary.regular')};
  }

  .table-column-subtitle {
    font-size: ${themeGet('fontSizes.xs')}px;
  }
`;

export const StyledTableBody = styled.div`
  ${StyledTableRow} {
    &:nth-child(odd) {
      ${StyledTableCell} {
        background-color: ${themeGet('colors.primary.light')};

        &:first-child {
          border-radius: ${themeGet('radii.base')} 0 0 ${themeGet('radii.base')};
        }

        &:last-child {
          border-radius: 0 ${themeGet('radii.base')} ${themeGet('radii.base')} 0;
        }
      }
    }
  }

  ${StyledTableCell} {
    padding: 20px;
  }
`;

export const StyledActionButton = styled.button`
  padding: 0;
  line-height: 0;
  vertical-align: middle;
  margin-left: 20px;
  background-color: transparent;
  color: ${themeGet('colors.text.regular')};
  transition: ${themeGet('customs.transition')};

  &:hover {
    color: ${themeGet('colors.primary.regular')};
  }
`;
