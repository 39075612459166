const DarkIcon = ({ width = 10, height = 16, color = 'currentColor' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.35647 9.304C8.75647 13.112 5.35647 15.984 1.50047 16C1.10847 16 0.724469 15.976 0.348469 15.92C-0.0515306 15.864 -0.131531 15.336 0.236469 15.152C2.86047 13.848 4.66047 11.136 4.66047 8C4.66047 4.864 2.86047 2.152 0.236469 0.839999C-0.123531 0.655999 -0.0515306 0.128 0.348469 0.0719995C0.724469 0.0240002 1.10847 0 1.50047 0C5.35647 0.0159998 8.75647 2.888 9.35647 6.696C9.42847 7.136 9.44447 7.568 9.44447 8C9.44447 8.432 9.42847 8.864 9.35647 9.304Z"
        fill={color}
      />
    </svg>
  );
};

export default DarkIcon;
