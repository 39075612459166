import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'gatsby';

import { Text } from 'components/common/text/text';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: min(720px, 100vw - 40px);
`;

export const Anchor = styled(Link)`
  display: block;
  text-decoration: underline;
  line-height: 2;
`;

export const BoldText = styled.span`
  font-weight: ${themeGet('fontWeights.bold')};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 40px;

  & button {
    width: 300px;
  }
`;

export const TextContainer = styled.div`
  margin: 60px 0;
`;

export const SubHeading = styled(Text)`
  margin-bottom: 20px;
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: ${themeGet('colors.body.light')};
  color: ${themeGet('colors.body.text')};
  border-radius: ${themeGet('radii.base')};
  right: 0;
  top: 0;
  border: none;
  outline: none;
  line-height: 0;

  @media ${themeGet('mediaQueries.bigDesktopUp')} {
    right: -150px;
  }
`;
