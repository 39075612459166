const CloseIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3007 0.70875C12.9107 0.31875 12.2807 0.31875 11.8907 0.70875L7.0007 5.58875L2.1107 0.69875C1.7207 0.30875 1.0907 0.30875 0.700703 0.69875C0.310703 1.08875 0.310703 1.71875 0.700703 2.10875L5.5907 6.99875L0.700703 11.8887C0.310703 12.2787 0.310703 12.9087 0.700703 13.2987C1.0907 13.6887 1.7207 13.6887 2.1107 13.2987L7.0007 8.40875L11.8907 13.2987C12.2807 13.6887 12.9107 13.6887 13.3007 13.2987C13.6907 12.9087 13.6907 12.2787 13.3007 11.8887L8.4107 6.99875L13.3007 2.10875C13.6807 1.72875 13.6807 1.08875 13.3007 0.70875Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
