import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Button = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  line-height: 0;
  background-color: ${themeGet('colors.primary.light')};
  color: ${themeGet('colors.body.text')};
  border-radius: ${themeGet('radii.base')};
  border: none;
  outline: none;
`;
