import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query {
    settings {
      languages
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query ($coinId: ObjectID!) {
    userSettings(coinId: $coinId) {
      coinId
      reward {
        blocked
        poolFee
        poolFeeMessage
      }
      withdraw {
        networkType
        blocked
        poolFee
        networkFee
        minAmount
        maxAmount
      }
    }
  }
`;
