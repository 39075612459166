import { useState, useEffect } from 'react';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { useWallet } from 'contexts/wallet/wallet.context';
import { heightCollapse } from 'utils/motion/height-collapse';
import { toggleOptions } from 'utils/constant';

import { FormRow, FormCover } from 'assets/styles/form.style';

import {
  StyledToggle,
  StyledActivateText,
  ToggleContainer,
  ToggleFlex,
  StyledWrapper,
  StyledTitle,
  Text
} from './toggle-button.style';

const ToggleButton = ({ name, value, onChange, children }) => {
  const { values } = useFormikContext();
  const { t } = useTranslation();
  const [val, setValue] = useState(value);

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  const onClickHandler = (value) => {
    setValue(value);

    if (isFunction(onChange)) {
      onChange({ target: { name, value } });
    }
  };

  return (
    <FormRow>
      <FormCover>
        <StyledToggle>
          <StyledWrapper>
            <StyledTitle>
              <h6>{t('walletsBlock.modal.activateTitle')}</h6>
            </StyledTitle>
            <ToggleContainer>
              <ToggleFlex>
                {toggleOptions.map((item) => (
                  <Text
                    className={cx({
                      'option--active': item.value === val
                    })}
                    onClick={
                      val !== item.value
                        ? () => onClickHandler(item.value)
                        : undefined
                    }
                  >
                    {t(item.label)}
                  </Text>
                ))}
              </ToggleFlex>
            </ToggleContainer>
          </StyledWrapper>
          <StyledActivateText>
            {val === 'day'
              ? t('walletsBlock.modal.activateDailyText')
              : t('walletsBlock.modal.activateHourlyText')}
          </StyledActivateText>
        </StyledToggle>
        <AnimatePresence>
          {values.autoWithdraw?.type === 'hour' && (
            <motion.div
              initial="from"
              animate="to"
              exit="from"
              variants={heightCollapse()}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </FormCover>
    </FormRow>
  );
};
export default ToggleButton;
