import CloseIcon from 'components/icons/CloseIcon';
import { Button } from './close-button.style';

const CloseButton = ({ handler }) => {
  return (
    <Button type="button" onClick={handler} aria-label="Close panel">
      <CloseIcon />
    </Button>
  );
};

export default CloseButton;
