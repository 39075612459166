import i18next from 'i18next';
import { useQuery } from '@apollo/client';

import {
  createContext,
  useMemo,
  useReducer,
  useEffect,
  useContext,
  useCallback
} from 'react';
import { ThemeProvider } from 'styled-components';

import { GET_SETTINGS } from 'graphql/settings.query';
import { baseTheme } from 'site-settings/site-theme/base-theme';
import lightTheme from 'site-settings/site-theme/light-theme';
import darkTheme from 'site-settings/site-theme/dark-theme';

const languageStorageItemKey = 'language';

const defaultState = {
  theme: 'light',
  language: 'EN',
  languages: ['en', 'ua', 'cn']
};

const ThemeContext = createContext(defaultState);

ThemeContext.displayName = 'ThemeContext';

const getTheme = (mode) => {
  const modeProps = mode === 'light' ? lightTheme : darkTheme;
  return { ...baseTheme, ...modeProps };
};

const themeReducer = (s, v) => ({ ...s, ...v });

export const ThemeContextProvider = ({ children }) => {
  const { data, loading } = useQuery(GET_SETTINGS);
  const [state, dispatch] = useReducer(themeReducer, defaultState);
  const { theme, language, languages } = state;
  const themeSettings = getTheme(theme);

  const switchTheme = useCallback(() => {
    theme === 'light'
      ? dispatch({ theme: 'dark' })
      : dispatch({ theme: 'light' });
  }, [theme]);

  const switchLanguage = (lang) => dispatch({ language: lang.toLowerCase() });

  const setLanguages = (languagesList) => dispatch({ languages: languagesList });

  const value = useMemo(
    () => ({
      ...state,
      languages,
      switchTheme,
      switchLanguage
    }),
    [state, languages]
  );

  useEffect(() => {
    if (!loading && data?.settings?.languages.length > 0) {
      setLanguages(data?.settings?.languages);
    }
  }, [data?.settings?.languages, loading]);

  useEffect(() => {
    const prefTheme = localStorage.getItem('theme') || defaultState.theme;
    const prefLanguage =
      localStorage.getItem(languageStorageItemKey) || defaultState.language;
    dispatch({ theme: prefTheme, language: prefLanguage });
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem(languageStorageItemKey, language.toLowerCase());
    i18next.changeLanguage(language.toLowerCase());
  }, [language]);

  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={themeSettings}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeContextProvider');
  }
  return context;
};
