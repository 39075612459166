import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { compose, flexbox, space } from 'styled-system';

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .variant-heading {
    display: flex;
    align-items: center;

    > i {
      margin-left: 13px;
      color: ${themeGet('colors.linkColor')};
      font-style: normal;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      font-size: ${themeGet('fontSizes.md')}px;

      span {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      > i {
        margin-left: 0;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  ${compose(flexbox, space)}
`;

export const HeadingText = styled.p`
  font-weight: ${themeGet('fontWeights.regular')};

  @media (min-width: 768px) {
    margin-left: 50px;
  }

  @media (max-width: 767px) {
    br {
      display: none;
    }
  }
`;
