import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  transition: all 0.25s ease;

  &.is-success {
    .styled-wrapper {
      background-color: ${themeGet('colors.success.regular')};
    }
  }

  &.is-error {
    .styled-wrapper {
      background-color: ${themeGet('colors.alerts.error')};
    }
  }

  .styled-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 30px;
    min-height: 60px;
    padding: 10px;
    width: 100%;

    p {
      line-height: 20px;
    }
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 30px;
  min-height: 60px;
  padding: 10px;
  width: min(500px, 100vw - 40px);

  p {
    line-height: 20px;
  }
`;

export const StyledCloseButton = styled.button`
  background-color: transparent;
  padding: 0;
  color: ${themeGet('colors.text.light')};
`;
