import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUI } from 'contexts/ui.context';
import { useDataContext } from 'contexts/data/data.context';
import { useTheme } from 'contexts/theme.context';
import CloseButton from '../close-button/close-button';

import {
  HeadWrapper,
  Title,
  Tabs,
  TabButton
} from './workers-modal-head.style';

const WorkersModalHead = () => {
  const { filterActive, filterInactive, workersActiveTab } = useDataContext();
  const { closeModal } = useUI();
  const { t } = useTranslation();
  const { language } = useTheme();
  const mediaWidth = language === 'ru' ? '615' : '523';

  useEffect(() => {
    return () => filterActive();
  }, []);

  return (
    <HeadWrapper>
      <Title>{t('workers.title')}</Title>

      <Tabs mediaWidth={mediaWidth}>
        <TabButton
          isInactive={workersActiveTab !== 'active'}
          onClick={filterActive}
        >
          {t('workers.active')}
        </TabButton>

        <TabButton
          isInactive={workersActiveTab !== 'inactive'}
          onClick={filterInactive}
        >
          {t('workers.inactive')}
        </TabButton>
      </Tabs>

      <CloseButton handler={closeModal} order={2} />
    </HeadWrapper>
  );
};

export default WorkersModalHead;
